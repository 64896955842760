import styled from 'styled-components'
import { styled as muiStyled } from '@mui/material/styles'

const SocialMediaTitle = styled.div`
  justify-content: space-around;
  width: 100%;
  font-family: Roboto;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: left;
`

const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
`

const SocialMediaIcon = styled.div`
    font-size: 24px;
    color: darkgray;
    cursor: pointer;
    margin-right: 18px;
    padding: 8px 10px 0px 10px;
    border: 2px solid;
    border-radius: 30px;
}
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 100vh;
  padding-top: 64px;
`

const Header = styled.div`
  background-color: #4b0082;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  height: 4rem;
`

const Body = muiStyled('div')(() => ({
  width: '100%',
  borderRadius: '25px',
  marginTop: '-25px',
  background: 'white',
  position: 'relative'
}))

const HeaderCircle = styled.div`
  background-color: #593493;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  margin-bottom: 10px;
  position: absolute;
  z-index: 1;
  top: -30px;
  right: calc(50% - 30px);
  border: solid 3px;
`

const Greeting = styled.h2`
  margin: 10px 0;
  color: black;
  text-align: center;
`

const ButtonGrid = styled.div`
    display: flex;
    flex-direction: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    max-height: 450px;
    margin: 20px 5% 0 5%;
    width= 100%;
`

const Button = styled.div`
  background-color: ${(props) => props.bgColor || '#ccc'};
  border-radius: 10px;
  width: 100%;
  height: 68px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
`
const ButtonMessage = styled.div`
  background-color: ${(props) => props.bgColor || '#ccc'};
  border-radius: 12px;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
`

export {
  SocialMediaTitle,
  SocialMediaContainer,
  SocialMediaIcon,
  Container,
  Header,
  Body,
  HeaderCircle,
  Greeting,
  ButtonGrid,
  Button,
  ButtonMessage
}
