import buildSignature from 'components/templates/PDF/shared/BasePage/Signature'
import { clone } from 'services/helpers'

const gradeLabelMap = {
  0: 'zero',
  1: 'um',
  2: 'dois',
  3: 'três',
  4: 'quatro',
  5: 'cinco',
  6: 'seis',
  7: 'sete',
  8: 'oito',
  9: 'nove',
  10: 'dez'
}

export function buildTableFooter({ classInfo, withSignature }) {
  const signature = buildSignature(
    classInfo.signatures,
    classInfo.poleCity || classInfo.unitCity,
    withSignature,
    3
  )
  return [
    [
      {
        colSpan: 7,
        text: 'Observações do curso',
        alignment: 'center',
        bold: true,
        style: ['tableHeaderCell']
      }
    ],
    [
      {
        colSpan: 7,
        style: ['tableBodyCell'],
        stack: [
          {
            text: [
              { text: 'Sistema de Avaliação: ', bold: true },
              `É considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, nota final igual ou superior a ${
                classInfo.minimumGrade
              } (${
                gradeLabelMap[classInfo.minimumGrade]
              }) pontos e frequência igual ou superior a 75% (setenta e cinco por cento).`
            ],
            margin: [0, 0, 0, 2]
          },
          {
            text: [
              { text: 'Observações: ', bold: true },
              'Notas precedidas de asterisco (*) são provenientes de aproveitamento de estudos.'
            ]
          }
        ]
      }
    ],
    [{ stack: [...clone(signature)], colSpan: 7, style: ['tableBodyCell'] }]
  ]
}
