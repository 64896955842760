import React, { useState, useEffect, lazy } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery, Button } from '@mui/material'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { useUserStore } from 'store/GlobalContext'
import {
  getStudent,
  getStudentDetailsFinances,
  getStudentHasOverdue
} from 'services/api/student'
import { usePaginationBasic, useSnack } from 'services/hooks'
import {
  getErrorMessage,
  getVisibleUpdatePassword,
  setClassesLocalStorage,
  setVisibleUpdatePassword,
  updateErrorMessage
} from 'services/helpers'
import { ERROR, ROUTE } from 'services/constants'
import { PageTitle } from 'components/atoms'
import { LoadingPage, Snackbar } from 'components/molecules'
import { TemplateStudentDiscipline as Template } from 'components/templates/Student'
import { DetailsFinancial } from 'components/organisms/Table'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { getTypeCourse } from './getTypeCourse'

import {
  initialStatus,
  switchDefault,
  studentDefault,
  statusList,
  itemsPerPage,
  ERROR_MESSAGE_DISCIPLINE,
  ERROR_MESSAGE_FINANCIAL
} from './constants'
import { BoxFinancialBreakdown } from './style'
import ModalStudentOverdue from './components/ModalStudentOverdue'

const CardDiscipline = lazy(() => import('./components/CardDisciplines'))
const FormDiscipline = lazy(() => import('./components/FormDisciplines'))

const Discipline = () => {
  const navigate = useNavigate()
  const { username: id } = useUserStore()
  const [student, setStudent] = useState([])
  const [type, setType] = useState(initialStatus)
  const [courseGrouping, setCourseGrouping] = useState('')
  const [switchValue, setSwitchValue] = useState(false)
  const { setLoading } = useLoading()

  const isVisibleUpdatePassword = getVisibleUpdatePassword()
  const { first_access: firstAccess } = useUserStore()
  const [studentOverdue, setStudentOverdue] = useState({})
  const [openModalStudentOverdue, setOpenModalStudentOverdue] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 769px)')
  const [buttonVisible, setButtonVisible] = useState(isDesktop)
  const shouldFilters = isDesktop || (!isDesktop && buttonVisible)
  const typeCourse = getTypeCourse(type)
  const schoolClassesIds = courseGrouping?.school_classes_ids
  const schoolClassesName = courseGrouping?.name
  const isValidSwitch = switchValue
    ? `${typeCourse}${switchDefault}`
    : typeCourse

  const sortedStudent = student?.sort((a, b) => {
    if (a.type < b.type) {
      return -1
    }
    return true
  })

  const { page, totalPage, items, setPage } = usePaginationBasic(
    sortedStudent,
    itemsPerPage
  )
  const { snack, setSnack, snackOpen, updateSnack } = useSnack()

  const onStudents = async () => {
    if (schoolClassesIds) {
      setLoading(true)
      updateSnack({})
      const getParamsListSubjects = {
        id,
        typeCourse: isValidSwitch,
        schoolClassesIds
      }
      const { data, error, status } = await getStudent(getParamsListSubjects)
      setStudent(data?.data)
      if (error) {
        const message = getErrorMessage(ERROR_MESSAGE_DISCIPLINE, status)
        setSnack(message, ERROR)
      }
    }
    setLoading(false)
  }

  const handleStudentHasOverdue = async ({ register }) => {
    const {
      data: overdue,
      error,
      status
    } = await getStudentHasOverdue({ register })

    if (error) {
      updateErrorMessage({
        error,
        status
      })
    }

    if (overdue) {
      setStudentOverdue(overdue)
      if (overdue.has_overdue) setOpenModalStudentOverdue(true)
    }
  }

  useEffect(() => {
    onStudents()
  }, [
    typeCourse,
    schoolClassesIds,
    switchValue,
    studentDefault,
    schoolClassesName
  ])

  useEffect(() => {
    setButtonVisible(isDesktop)
  }, [isDesktop])

  useEffect(async () => {
    setLoading(true)
    await Promise.all([handleStudentHasOverdue({ register: id })])
    setLoading(false)
  }, [])

  useEffect(() => {
    setClassesLocalStorage(courseGrouping)
  }, [courseGrouping])

  useEffect(() => {
    if (firstAccess && isVisibleUpdatePassword) {
      setVisibleUpdatePassword(false)
      navigate(ROUTE.STUDENT_UPDATE_PASSWORD)
    }
  }, [firstAccess, isVisibleUpdatePassword])

  return (
    <>
      <Template
        titleHeader={<PageTitle title="Minhas Disciplinas" divider />}
        content={
          <>
            <LoadingPage />

            <FormDiscipline
              statusList={statusList}
              setType={setType}
              setCourseGrouping={setCourseGrouping}
              switchValue={switchValue}
              setSwitchValue={setSwitchValue}
              shouldFilters={shouldFilters}
              setButtonVisible={setButtonVisible}
              buttonVisible={buttonVisible}
              setPage={setPage}
              setSnack={setSnack}
              updateSnack={updateSnack}
              isDesktop={isDesktop}
            />

            <CardDiscipline
              listClass={items}
              courseGrouping={courseGrouping}
              shouldFilters={shouldFilters}
              pages={page}
              totalPage={totalPage}
              setPage={setPage}
            />
          </>
        }
        snackAlert={
          <Snackbar
            message={snack.message}
            severity={snack.severity}
            shouldOpen={snackOpen}
          />
        }
      />
      <ModalStudentOverdue
        open={openModalStudentOverdue}
        amountOverdueBills={studentOverdue?.total_bills}
        onClose={() => setOpenModalStudentOverdue(false)}
      />
    </>
  )
}

export default Discipline
