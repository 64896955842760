import buildSignature from 'components/templates/PDF/shared/BasePage/Signature'
import { utilsPDF } from 'components/templates/PDF/shared/utils'
import htmlToPdfmake from 'html-to-pdfmake'
import { clone, dateFromPatternBR, toCamelCase } from 'services/helpers'

const { borderLayoutDefault, paddingLayoutDefault, noBorderOnTop } = utilsPDF

export function buildAcademicReportInfo({
  student,
  courseInfo,
  classInfo,
  withSignature
}) {
  const birthDate = dateFromPatternBR(student.birthDate).toLocaleString(
    'pt-BR',
    {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  )

  const classStartDate = new Date(classInfo.classStartDate).toLocaleString(
    'pt-BR',
    {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  )

  const conclusionDate = student.expeditionConclusionDate
    ? new Date(student.expeditionConclusionDate).toLocaleString('pt-BR', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })
    : ''

  const signature = buildSignature(
    classInfo.signatures,
    classInfo.poleCity || classInfo.unitCity,
    withSignature,
    3
  )

  const isForeigner = student.nationality?.toLowerCase() !== 'brasileiro(a)'
  const identifierText = isForeigner
    ? `portador(a) do CRNM nº ${student.crnm}`
    : `portador(a) da carteira de identidade nº ${student.rg}`

  return {
    table: {
      body: [
        [
          {
            text: 'Histórico Escolar',
            bold: true,
            alignment: 'center',
            border: noBorderOnTop
          }
        ],
        [
          {
            stack: [
              {
                text: `Conferimos a ${student.name.toUpperCase()}, natural de ${
                  student.placeOfBirthCity
                }/${
                  student.placeOfBirth
                } de nacionalidade ${student.nationality.toLowerCase()}, do sexo ${
                  student.sex === 'F' ? 'feminino' : 'masculino'
                }, nascido(a) em ${birthDate}, filho(a) de ${student.fatherName.toUpperCase()} e ${student.motherName.toUpperCase()}, ${identifierText}, a habilitação de ${toCamelCase(
                  courseInfo.academicReportTitle
                )}, do Eixo Tecnológico ${toCamelCase(
                  courseInfo.technologicalAxis
                )}`
              },
              {
                text: 'Fundamentação Legal: Lei nº 9394/96, de 20 de dezembro de 1996, Resolução CNE/CP nº 01/2021, de 05 de janeiro de 2021, Resolução CEE/MG nº 484/2021, de 26 de outubro de 2021.',
                margin: [0, 2, 0, 2]
              },
              {
                text: [
                  { text: 'Início do Curso ', bold: true },
                  `em ${classStartDate}`
                ]
              },
              {
                text: [
                  { text: 'Conclusão do Curso ', bold: true },
                  `em ${conclusionDate}`
                ]
              },
              ...clone(signature)
            ]
          }
        ],
        [{ stack: [htmlToPdfmake(courseInfo.gridComplementInformation)] }]
      ],
      widths: '*'
    },
    layout: {
      ...borderLayoutDefault,
      paddingBottom: () => 3,
      paddingLeft: () => 3,
      paddingRight: () => 3,
      paddingTop: () => 3
    }
  }
}
