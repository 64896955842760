import { Typography as MuiTypography } from '@mui/material'
import { getRgba } from 'services/helpers/polished'
import styled from 'styled-components'
import theme from 'theme/designTokens'

const TextNotFound = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin: 50px 0;
  svg {
    font-size: 5rem;
    color: ${getRgba(theme.palette.dark.main, 0.5)};
  }

  @media (max-width: 769px) {
    flex-direction: column;
  }
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: ${({ bold }) => (bold ? '700' : '400')};
    font-size: 1rem;
    color: ${getRgba(theme.palette.dark.main, 0.6)};
    line-height: 1.5rem;
    width: ${({ $width }) => $width || '50%'};
    padding: 0 0 0 2rem;

    @media (max-width: 769px) {
      width: 100%;
      text-align: center;
      padding: 0;
    }
  }
`

export { TextNotFound, Typography }
