export function gradeRuleNotFound(message) {
  return message === 'Regra de nota para essa disciplina não encontrada'
}

export function roundScore(score) {
  const decimalPart = Number((score - Math.floor(score)).toFixed(2))
  if (decimalPart >= 0.01 && decimalPart <= 0.44) {
    return Math.floor(score).toFixed(2)
  }
  if (decimalPart >= 0.45 && decimalPart <= 0.54) {
    return (Math.floor(score) + 0.5).toFixed(2)
  }
  if (decimalPart >= 0.55 && decimalPart <= 0.99) {
    return Math.ceil(score).toFixed(2)
  }

  return score.toFixed(2)
}
