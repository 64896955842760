import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Tabs from 'components/organisms/Tabs'
import { LoadingPage, Snackbar } from 'components/molecules'
import { useCurrentUser, useSnack } from 'services/hooks'
import { ERROR, UNITS } from 'services/constants'
import { Box } from '@mui/material'
import { currentYear, getErrorMessage, isDateBetween } from 'services/helpers'
import { gradeTabs } from './tabsGrades'
import { listBimestersByUnit } from '../services'
import { DiaryGradesProvider, useDiaryGradesContext } from './context/context'

export default function Grade() {
  return (
    <DiaryGradesProvider>
      <Content />
    </DiaryGradesProvider>
  )
}

const Content = () => {
  const { loadClassDetails, classDetails } = useDiaryGradesContext()
  const [searchParams] = useSearchParams()
  const user = useCurrentUser()
  const params = Object.fromEntries([...searchParams])
  const [isLoading, setIsLoading] = useState(false)
  const { snack, setSnack, snackOpen } = useSnack()

  const [unitBimesters, setUnitBimesters] = useState(null)
  const unitParam = params.unit || classDetails.unit
  const unit = Number.isNaN(+unitParam) ? unitParam : +unitParam

  const isNovoTech = Object.values(UNITS.NOVOTEC).includes(unit)
  const isMS = Object.values(UNITS.MS).includes(unit)
  const isLikeNovoTech = isNovoTech || isMS
  const isProfessor = user.role === 'professor'
  const showReclassification = !isMS

  useEffect(() => {
    if (isLikeNovoTech && isProfessor) {
      listBimestersByUnit({
        unit: isNovoTech ? 'novotec' : 'ms',
        year: currentYear(),
        onSuccess: (data) => {
          setUnitBimesters(data)
        },
        onError: (error) => {
          const message = getErrorMessage(...error)
          setSnack(message, ERROR)
        }
      })
    }
  }, [isNovoTech, isLikeNovoTech, isProfessor])

  const disableActionsForBimester = useCallback(
    (rowActivity) => {
      if (!unitBimesters) return false

      const bimesters = Object.values(unitBimesters)
      const isInBimester = bimesters.some(({ startDate, endDate }) => {
        const acitivityIsInBimester = isDateBetween(
          rowActivity.done_at,
          startDate,
          endDate
        )
        const currentDateIsInBimester = isDateBetween(
          new Date(),
          startDate,
          endDate
        )

        return acitivityIsInBimester && currentDateIsInBimester
      })

      return !isInBimester
    },
    [unitBimesters]
  )

  const tabParams = {
    setSnack,
    setIsLoading,
    isNovoTech,
    isMS,
    isProfessor,
    isLikeNovoTech,
    showReclassification,
    disableActions: disableActionsForBimester
  }

  const defaultTab = gradeTabs(tabParams)[0]
  const [value, setValue] = useState(defaultTab.index)

  useEffect(() => {
    if (params.classId) {
      loadClassDetails(params.classId)
    }
  }, [])

  return (
    <Box sx={{ marginTop: '24px' }}>
      <LoadingPage open={isLoading} />
      <Tabs
        customValue={[value, setValue]}
        contents={gradeTabs(tabParams).map(({ getContent }) =>
          getContent(tabParams)
        )}
        navigations={gradeTabs(tabParams).map(
          (gradeTab) => gradeTab.navigation
        )}
        justifyContent="end"
      />
      <Snackbar
        message={snack.message}
        severity={snack.severity}
        shouldOpen={snackOpen}
      />
    </Box>
  )
}
