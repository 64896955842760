import { HeaderDefault } from 'components/templates/PDF/shared/BasePage/Header'
import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { toCamelCase } from 'services/helpers'

const { borderLayoutDefault, paddingLayoutDefault, noBorderOnTop } = utilsPDF

export function buildSecondaryHeader({ student, courseInfo }) {
  return [
    HeaderDefault({
      indexOfCurrentElement: 1,
      content: {
        stack: [
          {
            text: 'Histórico Escolar',
            bold: true,
            fontSize: 12,
            lineHeight: 1.2,
            alignment: 'center',
            margin: [0, 5, 0, 0]
          },
          {
            text: 'Educação Profissional',
            bold: true,
            fontSize: 12,
            lineHeight: 1.2,
            alignment: 'center'
          }
        ]
      }
    }),
    {
      table: {
        body: [
          [
            {
              text: [{ text: 'Nome  ', bold: true }, student.name],
              colSpan: 4,
              border: noBorderOnTop
            },
            {},
            {},
            {},
            {
              text: [{ text: 'Matrícula  ', bold: true }, student.register],
              border: noBorderOnTop
            },
            {
              text: [
                { text: 'Sexo  ', bold: true },
                student.sex === 'F' ? 'Feminino' : 'Masculino'
              ],
              border: noBorderOnTop
            }
          ],
          [
            {
              stack: [
                {
                  text: [
                    { text: 'Data de Nascimento:  ', bold: true },
                    student.birthDate
                  ]
                },
                {
                  text: [
                    { text: 'Nacionalidade  ', bold: true },
                    student.nationality?.toUpperCase()
                  ]
                }
              ],
              colSpan: 2
            },
            {},
            {
              stack: [
                { text: [{ text: 'CPF.:  ', bold: true }, student.cpf] },
                { text: [{ text: 'C.I.  ', bold: true }, student.rg] }
              ],
              colSpan: 2
            },
            {},
            {
              stack: [
                {
                  text: [
                    { text: 'Filiação:  ', bold: true },
                    student.fatherName?.toUpperCase()
                  ]
                },
                { text: [{ text: student.motherName?.toUpperCase() }] }
              ],
              colSpan: 2
            },
            {}
          ],
          [
            {
              text: [
                { text: 'Ensino Médio:  ', bold: true },
                `${student.school} - Data: ${student.schoolYear} - Local: ${student.schoolLocation}`
              ],
              colSpan: 6
            }
          ],
          [
            {
              stack: [
                {
                  text: toCamelCase(courseInfo.academicReportTitle),
                  bold: true
                },
                {
                  text: `Eixo Tecnológico: ${toCamelCase(
                    courseInfo.technologicalAxis
                  )}`,
                  bold: true,
                  margin: [0, 1, 0, 1]
                },
                {
                  text: [
                    { text: 'Reconhecimento: ', bold: true },
                    courseInfo.resolutionCourse
                  ]
                }
              ],
              colSpan: 6,
              alignment: 'center'
            }
          ]
        ],
        widths: '*'
      },
      layout: {
        ...borderLayoutDefault,
        paddingBottom: () => 3,
        paddingLeft: () => 3,
        paddingRight: () => 3,
        paddingTop: () => 3
      }
    }
  ]
}
