export const STUDENT_EVENTS = {
  financial: {
    tab: {
      openMeusTitulos: 'student_financeiro_tab_open_meustitulos',
      openGerenciarPagamentos: 'student_financeiro_tab_open_gerenciarpagamentos'
    },
    select: {
      status: 'student_financeiro_select_status'
    },
    btn: {
      filterFiltrar: 'student_financeiro_btn_filter_filtrar'
    },
    tableResume: {
      openNFE: 'student_financeiro_table_resumo_btn_open_nfe',
      openNFSE: 'student_financeiro_table_resumo_btn_open_nfse',
      openCartao: 'student_financeiro_table_resumo_btn_open_cartao',
      openBoleto: 'student_financeiro_table_resumo_btn_open_boleto',
      openPIX: 'student_financeiro_table_resumo_btn_open_pix'
    },
    tableTitulos: {
      openCartao: 'student_financeiro_table_titulos_btn_open_cartao',
      openPIX: 'student_financeiro_table_titulos_btn_open_pix',
      openRenegociarDivida:
        'student_financeiro_table_titulos_btn_open_renegociar_divida',
      openBoleto: 'student_financeiro_table_titulos_btn_open_boleto',
      openNfe: 'student_financeiro_table_titulos_btn_open_nfe',
      openNfse: 'student_financeiro_table__titulos_btn_open_nfse'
    },
    managePayments: {
      openChangeCard: 'student_financeiro_btn_open_alterarcartao'
    }
  },
  gradesAndAbsences: {
    select: {
      course: 'student_notasefaltas_select_curso',
      result: 'student_notasefaltas_select_resultado'
    }
  },
  disciplines: {
    select: {
      course: 'student_disciplinas_select_curso',
      status: 'student_disciplinas_select_status'
    },
    card: {
      openEAD: 'student_disciplinas_card_open_ead'
    }
  }
}
