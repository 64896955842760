import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { capitalizeWords } from 'services/helpers'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export function buildRecordInfo({ classInfo, student }) {
  return {
    table: {
      body: [
        [
          {
            text: 'Histórico Escolar',
            bold: true,
            colSpan: 2,
            fontSize: 11,
            alignment: 'center'
          },
          {}
        ],
        [
          {
            stack: [
              {
                text: [
                  { text: 'Unidade escolar: ', bold: true },
                  classInfo.poleAcademicName
                ],
                margin: [0, 0, 0, 5]
              },
              {
                text: [
                  { text: 'Endereço: ', bold: true },
                  `${classInfo.poleAddress}, ${classInfo.poleAddressNumber}`
                ],
                margin: [0, 0, 0, 5]
              },
              { text: [{ text: 'CEP: ', bold: true }, classInfo.poleCEP] }
            ]
          },
          {
            stack: [
              {
                text: [{ text: 'Município: ', bold: true }, classInfo.poleCity],
                margin: [0, 0, 0, 5]
              },
              {
                text: [
                  { text: 'Bairro: ', bold: true },
                  classInfo.poleDistrict
                ],
                margin: [0, 0, 0, 5]
              },
              {
                text: [
                  { text: 'Telefone: ', bold: true },
                  classInfo.poleTelephone
                ]
              }
            ]
          }
        ],
        [
          {
            stack: [
              {
                text: [
                  { text: 'Ato de Criação: ', bold: true },
                  { text: classInfo.poleResolution }
                ]
              },
              {
                text: [
                  {
                    text: 'Credenciamento para Ofertar a Educação Profissional: ',
                    bold: true
                  },
                  {
                    text: classInfo.poleCredential
                  }
                ]
              },
              {
                text: [
                  {
                    text: 'Autorização de Funcionamento do Projeto Pedagógico do Itinerário Formativo Profissional: ',
                    bold: true
                  },
                  'Resolução/SED Nº 3.997, de 3 de fevereiro de 2022.'
                ]
              }
            ],
            colSpan: 2
          },
          {}
        ],
        [
          {
            text: [
              { text: 'CERTIFICAMOS que ' },
              { text: student.name.toUpperCase(), bold: true },
              ', nascido(a) em ',
              { text: student.birthDate, bold: true },
              ', tendo em vista os resultados obtidos do Ano Letivo de ',
              {
                text: new Date(classInfo.classStartDate).getFullYear(),
                bold: true
              },
              ' foi considerado(a) ',
              { text: 'CONCLUINTE', bold: true },
              ' da ',
              {
                text: `QUALIFICAÇÃO PROFISSIONAL EM ${classInfo.course.toUpperCase()}`,
                bold: true
              },
              ', nos termos da lei nº 9.394, de dezembro de 1996, conforme os resultados do histórico escolar constantes abaixo:'
            ],
            colSpan: 2
          },
          {}
        ]
      ],
      widths: '*'
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}
