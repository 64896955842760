import { useNavigate } from 'react-router-dom'
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  Tooltip
} from '@mui/material'

import { ROUTE, UNITS } from 'services/constants'
import { useSortTable } from 'services/hooks'
import { convertToParams, formatDate, paginationFilter } from 'services/helpers'
import { defaultlistPerPage } from 'services/hooks/constants'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'

import {
  KEY_COLUMNS,
  tableColumns,
  tooltipTextExclude,
  tooltipTextManagerGrouping
} from './constants'

import * as Styled from './style'

const List = ({
  limit,
  page,
  items,
  count,
  totalPage,
  handleChange,
  handleSetPage,
  handleEditGrouping,
  handleEditTeacher,
  handleExcludeGrouping,
  handleAddTeacher
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    items,
    KEY_COLUMNS.NAME
  )

  const navigate = useNavigate()

  return (
    <Styled.Wrapper>
      <Paper>
        <Table size="medium">
          <TableHeaderDefault
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableColumns}
          />
          <TableBody>
            {sortTable?.map((item) => {
              const {
                discipline,
                id: groupingId,
                groupingDisciplines,
                startDate,
                endDate,
                activeStudentCount,
                grouping
              } = item

              const hasTeacher = item?.professorId

              const handleManagerGrouping = () => {
                navigate(`${ROUTE.ADMIN_SECRETARY_GROUPING_MANAGER}`, {
                  state: {
                    grouping,
                    groupingId,
                    groupingDisciplines: groupingDisciplines?.map((id) =>
                      Number(id)
                    )
                  }
                })
              }

              const handleAccessNoteDiaries = () => {
                navigate(
                  `${ROUTE.ADMIN_SECRETARY_GROUPING_DIARY}?${convertToParams({
                    classId: item.id,
                    disciplineId: item.groupingDisciplines.join(','),
                    group: item?.grouping,
                    professorId: item.professorId,
                    unit: item.unit
                  })}`
                )
              }

              const isAvailableToExcludeGrouping = true

              return (
                <Styled.TableRow key={groupingId}>
                  <TableCell padding="checkbox" style={{ width: '30%' }}>
                    <Styled.WrapperLink>
                      <Tooltip title="Editar">
                        <Link
                          onClick={() => handleEditGrouping(item)}
                          component="button"
                          href="#root"
                        >
                          {`${grouping} [${groupingId}]`}
                        </Link>
                      </Tooltip>

                      <Styled.Actions>
                        {item?.disciplineIdHash && (
                          <Tooltip title="" placement="top" className="actions">
                            <Styled.AppRegistrationIcon
                              onClick={handleAccessNoteDiaries}
                            />
                          </Tooltip>
                        )}

                        <Tooltip
                          title={tooltipTextManagerGrouping}
                          placement="top"
                          className="actions"
                        >
                          <Styled.NoteAddIcon onClick={handleManagerGrouping} />
                        </Tooltip>

                        <Tooltip
                          title={
                            isAvailableToExcludeGrouping
                              ? tooltipTextExclude.available
                              : tooltipTextExclude.dontAvailable
                          }
                          placement="top"
                          className="actions"
                        >
                          <Styled.DeleteIcon
                            $disabled={!isAvailableToExcludeGrouping}
                            onClick={() => handleExcludeGrouping(item)}
                          />
                        </Tooltip>
                      </Styled.Actions>
                    </Styled.WrapperLink>
                  </TableCell>
                  <TableCell>{discipline}</TableCell>
                  <TableCell>
                    <Styled.Period>
                      <Styled.PeriodDate>
                        {formatDate(startDate)}
                      </Styled.PeriodDate>
                      <Styled.PeriodDate>
                        {formatDate(endDate)}
                      </Styled.PeriodDate>
                    </Styled.Period>
                  </TableCell>
                  <TableCell
                    style={{ width: '10%' }}
                  >{`${activeStudentCount}`}</TableCell>

                  <TableCell padding="checkbox" style={{ width: '20%' }}>
                    <Styled.WrapperLink>
                      {hasTeacher ? (
                        <Tooltip title="Editar Professor">
                          <Link
                            onClick={() => handleEditTeacher(item)}
                            component="button"
                            href="#root"
                          >
                            {`${item?.professor} [${item?.professorId}]`}
                          </Link>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Adicionar professor">
                          <Styled.AddTeacher
                            onClick={() => handleAddTeacher(item)}
                          >
                            <Styled.AddIcon /> ADICIONAR
                          </Styled.AddTeacher>
                        </Tooltip>
                      )}
                    </Styled.WrapperLink>
                  </TableCell>
                </Styled.TableRow>
              )
            })}
          </TableBody>
        </Table>

        {count > defaultlistPerPage && (
          <PaginationAdvanced
            isSelectItensPerPage
            handleChange={handleChange}
            handleSetPage={handleSetPage}
            count={totalPage}
            optionsList={paginationFilter}
            page={page}
            smallSize
            value={limit}
          />
        )}
      </Paper>
    </Styled.Wrapper>
  )
}

export default List
