import { coatOfArmsMsSVG } from 'components/templates/PDF/shared/images/coatOfArmsMS'
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { borderLayoutDefault, paddingLayoutDefault, logoProzSVG } = utilsPDF

export function buildHeader() {
  return {
    table: {
      body: [
        [
          {
            svg: coatOfArmsMsSVG,
            alignment: 'center',
            width: 60
          },
          {
            stack: [
              {
                text: 'Estado de Mato Grosso do Sul',
                bold: true,
                fontSize: 14,
                lineHeight: 1.2,
                alignment: 'center',
                margin: [0, 15, 0, 0]
              },
              {
                text: 'Secretaria de Estado de Educação',
                bold: true,
                lineHeight: 1.2,
                fontSize: 12,
                alignment: 'center',
                margin: [0, 5, 0, 0]
              }
            ]
          },
          {
            margin: [0, 15, 0, 0],
            svg: logoProzSVG,
            width: 100,
            alignment: 'center'
          }
        ]
      ],
      widths: [120, '*', 120]
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}
