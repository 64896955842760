import { TemplateStudentMyScores as Template } from 'components/templates/Student'
import { LoadingPage, Snackbar } from 'components/molecules'
import { useSnack, usePaginationBasic } from 'services/hooks'
import { PageTitle } from 'components/atoms'
import { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { setClassesLocalStorage } from 'services/helpers'
import { useUserStore } from 'store/GlobalContext'
import { useTheme } from 'styled-components'
import ContentMyScores from './components/ContentMyScores'
import FormDiscipline from '../Discipline/components/FormDisciplines'
import {
  initialStatus,
  statusList,
  itemsPerPage
} from '../Discipline/constants'

const MyScores = () => {
  const { snack, setSnack, snackOpen, updateSnack } = useSnack()
  const { username: register } = useUserStore()
  const [student, setStudent] = useState([])
  const [type, setType] = useState(initialStatus)
  const [switchValue, setSwitchValue] = useState(false)
  const [courseGrouping, setCourseGrouping] = useState('')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const [buttonVisible, setButtonVisible] = useState(isDesktop)
  const shouldFilters = isDesktop || (!isDesktop && buttonVisible)

  const sortedStudent = student?.sort((a, b) => {
    if (a.type < b.type) {
      return -1
    }
    return true
  })
  const { page, totalPage, items, setPage } = usePaginationBasic(
    sortedStudent,
    itemsPerPage
  )

  useEffect(() => {
    setClassesLocalStorage(courseGrouping)
  }, [courseGrouping])

  useEffect(() => {
    setButtonVisible(isDesktop)
  }, [isDesktop])

  return (
    <Template
      titleHeader={<PageTitle title="Minhas Notas" divider />}
      content={
        <>
          <LoadingPage />
          <FormDiscipline
            statusList={statusList}
            setType={setType}
            setCourseGrouping={setCourseGrouping}
            switchValue={switchValue}
            setSwitchValue={setSwitchValue}
            shouldFilters={shouldFilters}
            shouldFilterStatus={false}
            setButtonVisible={setButtonVisible}
            buttonVisible={buttonVisible}
            setPage={setPage}
            setSnack={setSnack}
            updateSnack={updateSnack}
            isDesktop={isDesktop}
          />
          <ContentMyScores
            setSnack={setSnack}
            updateSnack={updateSnack}
            studentRegister={register}
            studentSchoolClassId={courseGrouping?.school_classes_ids}
          />
        </>
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default MyScores
