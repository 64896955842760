import { createContext, useContext, useState } from 'react'

const initialState = {
  openModalResultsReport: false,
  setOpenModalResultsReport: () => {},
  classDetails: null,
  setClassDetails: () => {},
  classId: null,
  unitId: null,
  setUnitId: (value) => {},
  setClassId: (value) => {},
  handleCloseModalResultsReport: () => {}
}

export const ClassDetailsContext = createContext(initialState)

export function ClassDetailsProvider({ children }) {
  const [openModalResultsReport, setOpenModalResultsReport] = useState(
    initialState.openModalResultsReport
  )
  const handleCloseModalResultsReport = () => setOpenModalResultsReport(false)
  const [classDetails, setClassDetails] = useState(null)
  const [classId, setClassId] = useState(initialState.classId)
  const [unitId, setUnitId] = useState()

  return (
    <ClassDetailsContext.Provider
      value={{
        openModalResultsReport,
        setOpenModalResultsReport,
        classId,
        setClassId,
        handleCloseModalResultsReport,
        unitId,
        setUnitId,
        classDetails,
        setClassDetails
      }}
    >
      {children}
    </ClassDetailsContext.Provider>
  )
}

export const useClassDetailsContext = () => useContext(ClassDetailsContext)
