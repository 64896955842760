import React, { useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'services/helpers/fp'
import { getStudentGradesResume } from 'services/api/professor'
import { useCurrentUser } from 'services/hooks'
import { ResumeTable } from 'components/organisms/Table'
import { PageTitle } from 'components/atoms'
import {
  EX_FINAL_GRADE_KEY,
  headerCells,
  RECLASSIFICATION_GRADE_KEY
} from './config'

const Resume = ({ params }) => {
  const [resume, setResume] = useState(null)
  const { professorId, classId, disciplineId } = useCurrentUser()
  const { setIsLoading, isLikeNovoTech, showReclassification } = params

  const hiddeColumns = useMemo(() => {
    const columnsIds = []

    if (!isLikeNovoTech) {
      columnsIds.push(EX_FINAL_GRADE_KEY)
    }

    if (!showReclassification) {
      columnsIds.push(RECLASSIFICATION_GRADE_KEY)
    }

    return columnsIds
  }, [isLikeNovoTech, showReclassification])

  const findStudentListFromClass = async () => {
    setIsLoading(true)

    const convertByArray = JSON.parse(`[${disciplineId}]`)
    const formattedParams = {
      professorId,
      classId,
      disciplineId: convertByArray
    }

    const { data: response } = await getStudentGradesResume(formattedParams)

    setResume(response)
    setIsLoading(false)
  }

  useEffect(() => {
    findStudentListFromClass()
  }, [])

  return (
    <>
      <ResumeTable
        resume={resume?.students}
        headCells={headerCells}
        dropouts
        hiddeColumns={hiddeColumns}
      />
      {resume?.dropouts &&
        !isEmpty(resume?.students) &&
        !isEmpty(resume?.dropouts) && (
          <>
            <PageTitle title="Alunos desistentes/cancelados/remanejados" />
            <ResumeTable
              resume={resume?.dropouts}
              headCells={headerCells}
              dropouts={false}
              hiddeColumns={hiddeColumns}
            />
          </>
        )}
    </>
  )
}

export default Resume
