import Resume from './Resume'
import Assessments from './Assessments'
import Diversified from './Diversified'
import Recovery from './Recovery'
import Reclassification from './Reclassification'
import ExFinal from './ExFinal'

const gradeTabs = ({
  isNovoTech,
  isLikeNovoTech,
  isProfessor,
  showReclassification = true
}) => [
  ...(isProfessor && isNovoTech
    ? []
    : [
        {
          index: 0,
          getContent: ({ ...params }) => <Resume params={params} />,
          navigation: 'Resumo',
          title: 'Resumo'
        }
      ]),
  {
    index: 1,
    getContent: ({ ...params }) => <Assessments params={params} />,
    navigation: 'Avaliações Presenciais',
    title: 'Avaliações Presenciais'
  },
  {
    index: 2,
    getContent: ({ ...params }) => <Diversified params={params} />,
    navigation: 'Atividades Online',
    title: 'Atividades Online'
  },
  {
    index: 3,
    getContent: ({ ...params }) => <Recovery params={params} />,
    navigation: 'Recuperação',
    title: 'Recuperação'
  },
  ...(showReclassification
    ? [
        {
          index: 4,
          getContent: ({ ...params }) => <Reclassification params={params} />,
          navigation: 'Reclassificação',
          title: 'Reclassificação'
        }
      ]
    : []),
  ...(isLikeNovoTech
    ? [
        {
          index: 5,
          getContent: ({ ...params }) => <ExFinal params={params} />,
          navigation: 'EXFINAL',
          title: 'ExFinal'
        }
      ]
    : [])
]

export { gradeTabs }
