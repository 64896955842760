import { UNITS } from 'services/constants'

const { utilsPDF } = require('../../shared/utils')

const { borderLayoutDefault, paddingLayoutDefault, noBorderOnTop } = utilsPDF

const getRunningTextAboutClassGeneral = (info) => {
  const date = new Date()
  const year = date.getUTCFullYear()
  const mounth = date.toLocaleString('pt-BR', { month: 'long' })
  const day = date.getUTCDate()

  return [
    {
      text: `Aos ${day} dias do mês de ${mounth} de ${year}, concluiu-se o processo de apuração do Resultado Final dos alunos do(a) `
    },
    { text: `${info.course}`, bold: true },
    {
      text: `, turma ${info.classroom}, turno ${info.shift}, desta instituição de Ensino com os seguintes resultados:`
    }
  ]
}

const getRunningTextAboutClassMS = (info) => {
  const date = new Date()
  const year = date.getUTCFullYear()
  const mounth = date.toLocaleString('pt-BR', { month: 'long' })
  const day = date.getUTCDate()

  return [
    {
      text: `Aos ${day} dias do mês de ${mounth} de ${year}, concluiu-se o processo de apuração do Resultado Final dos alunos do(a) `
    },
    { text: `Qualificação profissional em ${info.course}`, bold: true },
    {
      text: `, turma ${info.classroom}, turno ${info.shift}, desta instituição de Ensino com os seguintes resultados:`
    }
  ]
}

function getClassInfoMS(info) {
  const closedClass = new Date(info.classEndDate) < new Date()
  const academicYear = closedClass
    ? new Date(info.classEndDate).getFullYear()
    : new Date().getFullYear()

  return {
    table: {
      body: [
        [
          {
            stack: [{ text: 'Nível', bold: true }, { text: info.level }],
            border: noBorderOnTop
          },
          {
            stack: [{ text: 'Curso', bold: true }, { text: info.course }],
            border: noBorderOnTop
          },
          {
            stack: [{ text: 'Ano letivo', bold: true }, { text: academicYear }],
            border: noBorderOnTop
          }
        ],
        [{ text: getRunningTextAboutClassMS(info), colSpan: 3 }, {}, {}]
      ],
      widths: ['*', '*', 50]
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}

function getClassInfoGeneral(info) {
  const closedClass = new Date(info.classEndDate) < new Date()
  const academicYear = closedClass
    ? new Date(info.classEndDate).getFullYear()
    : new Date().getFullYear()

  return {
    table: {
      body: [
        [
          {
            stack: [{ text: 'Curso', bold: true }, { text: info.course }],
            border: noBorderOnTop
          },

          {
            stack: [{ text: 'Ano letivo', bold: true }, { text: academicYear }],
            border: noBorderOnTop,
            colSpan: 2
          }
        ],
        [{ text: getRunningTextAboutClassGeneral(info), colSpan: 3 }, {}, {}]
      ],
      widths: ['*', 50, 50]
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}

export function buildClassInfo(info) {
  const isMS = UNITS.MS.name === info.unit

  switch (true) {
    case isMS:
      return getClassInfoMS(info)

    default:
      return getClassInfoGeneral(info)
  }
}
