const initialValues = {
  year: null,
  bimester: null
}

const bimesterMapper = {
  firstBimester: '1º Bimestre',
  secondBimester: '2º Bimestre',
  thirdBimester: '3º Bimestre',
  fourthBimester: '4º Bimestre'
}

export { bimesterMapper, initialValues }
