import styled from 'styled-components'
import { Divider, Typography } from '@mui/material'

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 10px 0;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 24px 0 0 0;
    align-items: start;
  }
`
const Card = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0 10px 0;

  @media (max-width: 768px) {
    align-items: start;
    flex-direction: column;
    padding: 0;
  }
`

const TitleCourse = styled(Typography)`
  white-space: nowrap;

  &.MuiTypography-root {
    margin-bottom: 16px;
    margin-right: 6px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const BoxInfo = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;
  }
`

const Course = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const Status = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    align-items: start;
    flex-direction: column;
    width: 100%;
  }
`

const DividerHeader = styled(Divider)`
  @media (max-width: 768px) {
    display: none;
  }
`

const Switch = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 16px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const BoxFilter = styled.div`
  display: none;

  @media (max-width: 768px) {
    margin: 10px 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`

const BoxForm = styled.div`
  margin-bottom: 16px;
  width: 100%;
`

export {
  Header,
  Course,
  Card,
  TitleCourse,
  BoxInfo,
  Status,
  DividerHeader,
  Switch,
  BoxFilter,
  BoxForm
}
