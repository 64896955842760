import { ROUTE } from 'services/constants'

const initialValues = { checked: [] }

const initialValuesClasses = {
  lesson_date: '',
  hourly_lesson_cost: '',
  lesson_hour: '',
  professor_id: '',
  activity: 'Curso de Aprimoramento'
}

const MESSAGE = {
  SUCCESS_CHECKED: 'Turma confirmada com sucesso!',
  titleAcc: 'Adicione aula ou atividade extras para professores dessa turma:'
}

const breadcrumbsItems = ({ params }) => [
  {
    path: ROUTE.ADMIN_HR_TIMEKEEPING,
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_TIMEKEEPING,
    label: 'Apontamento de Horas'
  },
  {
    path: `${ROUTE.ADMIN_HR_MANAGE_CLASSES}?payroll_id=${params.payrollId}&unit=${params.unit}`,
    label: 'Gerenciar Turmas'
  },
  {
    path: ROUTE.ADMIN_HR_MANAGE_CLASSES,
    label: `<b>${params.name}</b>`
  }
]
const numberOfDaysForAddExtraActivity = 15

export {
  breadcrumbsItems,
  initialValues,
  MESSAGE,
  initialValuesClasses,
  numberOfDaysForAddExtraActivity
}
