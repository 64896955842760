import { Outlet, useLocation } from 'react-router-dom'
import Container from '@mui/material/Container'
import Header from 'components/organisms/Header'
import { useTutorialContext } from 'components/pages/Shared/Welcome/Context/hooks'
import * as Styled from './style'

const getStyleConfig = (pathname) => {
  const paths = ['/admin', '/student/hub', '/student/unitinfo']

  if (paths.includes(pathname)) {
    return {
      maxWidth: 'none',
      padding: 0
    }
  }

  return {
    maxWidth: 1200
  }
}

const TemplatePrivate = () => {
  const { pathname } = useLocation()
  return (
    <div style={{ display: 'flex' }}>
      <Styled.WrapperContainer>
        <Header />
        <Container style={getStyleConfig(pathname)}>
          <Outlet />
        </Container>
      </Styled.WrapperContainer>
    </div>
  )
}

export default TemplatePrivate
