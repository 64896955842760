import styled from 'styled-components'
import { Grid as MuiGrid, Typography } from '@mui/material'
import { Modal as MuiModal } from 'components/atoms'

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 790px;
  }
`

const Form = styled(MuiGrid)`
  padding: 21px;
`

const ButtonsContainer = styled(MuiGrid)`
  display: flex;
  justify-content: flex-end;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

export { Modal, Form, Title, ButtonsContainer }
