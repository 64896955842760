/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { httpDelete, httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams, isEmpty } from 'services/helpers'
import { PROFESSOR_ENDPOINT } from 'services/constants/endpoints/professor'
import { urlSchoolClassID } from './constants'
import { constructDownloadUrl } from './helpers'

export const getListSchoolClass = async (
  hasGroup,
  hasPole,
  params,
  groups,
  poles
) => {
  const URL_ENDPOINT = `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.LIST}`
  const hasGroupOrPole = !isEmpty(hasGroup) || !isEmpty(hasPole)
  const groupsQueryParam = !isEmpty(hasGroup) ? `&${groups}` : ''
  const polesQueryParam = !isEmpty(hasPole) ? `&${poles}` : ''

  if (hasGroupOrPole) {
    const responseWithArray = await httpGet(
      `${URL_ENDPOINT}?${convertToParams(
        params
      )}${groupsQueryParam}${polesQueryParam}`
    )
    return responseWithArray
  }

  const response = await httpGet(`${URL_ENDPOINT}?${convertToParams(params)}`)

  return response
}

export const createDetailsProfessors = async (payload, idDisc) => {
  const response = await httpPut(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.DISCIPLINES}/${idDisc}/${ENDPOINT.SCHOOL_CLASS.PROFESSORS}`,

    payload
  )

  return response
}

export const getCoordinators = async () => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.COORDINATORS}`
  )

  return response
}

export const createBatchActionProfessors = async (payload) => {
  const response = await httpPut(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.DISCIPLINES}/${ENDPOINT.SCHOOL_CLASS.PROFESSOR}`,
    payload
  )

  return response
}

export const getDetailsBigNumbers = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${ENDPOINT.SCHOOL_CLASS.BIG_NUMBERS}`
  )

  return response
}

export const getShifts = async () => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.SHIFTS}`
  )

  return response
}

export const getModules = async () => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.MODULES}`
  )

  return response
}

export const getOptionsCourses = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${
      ENDPOINT.SCHOOL_CLASS.COURSES
    }?${convertToParams(params)}`
  )

  return response
}

export const getLevels = async () => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.LEVELS}`
  )

  return response
}

export const getModalities = async () => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.MODALITIES}`
  )

  return response
}

export const getEvaluations = async () => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.EVALUATIONS}`
  )

  return response
}

export const getDetailsExportGradeFiles = async (params, id) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${
      ENDPOINT.SCHOOL_CLASS.GRADES_FILES
    }?${convertToParams(params)}`
  )

  return response
}

export const getDetailsExportResultTransciptsFiles = async (params, id) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${
      ENDPOINT.SCHOOL_CLASS.RESULT_TRANSCRIPT
    }?${convertToParams(params)}`
  )

  return response
}

export const getDetailsGradeSheets = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.GRADE_SHEETS}?${convertToParams(params)}`
  )

  return response
}

export const getDetailsListClassDetails = async (id) => {
  const response = await httpGet(`${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/details`)

  return response
}

export const getDetailsListProfessors = async () => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.PROFESSORS}`
  )

  return response
}

export const getDetailsListTimes = async (id) => {
  const response = await httpGet(`${ENDPOINT.SCHOOL_CLASS.SCHEDULES}/${id}`)

  return response
}

export const downloadOccurrences = async ({
  params,
  occurrenceIds,
  schoolClassIds
}) => {
  const downloadUrl = constructDownloadUrl(
    occurrenceIds,
    schoolClassIds,
    params
  )

  const response = await httpGet(downloadUrl, {
    responseType: 'blob'
  })

  return response
}

export const createDetailsNewSchedules = async (payload) => {
  const response = await httpPut(`${ENDPOINT.SCHOOL_CLASS.SCHEDULES}`, payload)

  return response
}

export const getDetailsSchoolClassListDisciplines = async ({ params, id }) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${
      ENDPOINT.SCHOOL_CLASS.DISCIPLINES
    }?${convertToParams(params)}`
  )

  return response
}

export const updateDetailsSchoolClass = async (id, payload) => {
  const response = await httpPut(`${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}`, payload)
  return response
}

export const getGeneralReport = async (
  hasSchoolClassId,
  params,
  schoolClassId
) => {
  const URL_ENDPOINT = `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.GENERAL_REPORT}`
  const schoolClassIdQueryParam = !isEmpty(hasSchoolClassId)
    ? `&${schoolClassId}`
    : ''

  const response = await httpGet(
    `${URL_ENDPOINT}?${convertToParams(params)}${schoolClassIdQueryParam}`
  )

  return response
}

export const getTrailsReport = async (
  hasSchoolClassId,
  params,
  schoolClassId
) => {
  const schoolClassIdQueryParam = !isEmpty(hasSchoolClassId)
    ? `&${schoolClassId}`
    : ''
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/reports/trilhas?${convertToParams(
      params
    )}${schoolClassIdQueryParam}`,
    {
      responseType: 'blob'
    }
  )
  return response
}

export const getGradeSheets = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.GRADE_SHEETS}?${convertToParams(params)}`
  )

  return response
}

export const getSchoolClassesSubjects = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.SUBJECT}?${convertToParams(params)}`
  )

  return response
}

export const createSchoolClasses = async (payload) => {
  const response = await httpPost(`${ENDPOINT.SCHOOL_CLASS.CREATE}`, {
    ...payload
  })

  return response
}

export const sendAPIJoy = async (params) => {
  const URL_ENDPOINT = `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.SEND_JOY}`

  const response = await httpGet(
    `${URL_ENDPOINT}?${!isEmpty(params) && `&${params}`}`
  )

  return response
}

export const sendEmail = async (payload, params, schoolClassIds) => {
  const paramsString = Object.keys(params).reduce(
    (acc, key) => (acc += `&${key}=${!!params[key]}`),
    ''
  )

  const URL_ENDPOINT = `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.SEND_EMAIL}`

  const response = await httpPost(
    `${URL_ENDPOINT}?${`${schoolClassIds}${paramsString}`}`,
    payload
  )

  return response
}

export const deleteClass = async (params) => {
  const response = await httpDelete(`${ENDPOINT.SCHOOL_CLASS.DELETE}`, {
    ...params
  })
  return response
}

export const deleteSchoolClass = async (params) => {
  const response = await httpDelete(`${ENDPOINT.SCHOOL_CLASS.CRUD}`, {
    ...params
  })
  return response
}

export const getListReenroll = async (params, id) => {
  const response = await httpGet(
    `${urlSchoolClassID(id)}?${convertToParams(params)}`
  )

  return response
}

export const inactiveReenrollClass = async (payload) => {
  const response = await httpPut(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.INACTIVE}`,
    {
      ...payload
    }
  )

  return response
}

export const createReenrollClass = async (id, payload) => {
  const response = await httpPut(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.RE_ENROLLMENT}/${id}/period`,
    {
      ...payload
    }
  )
  return response
}

export const createReenrollSubmit = async (id, payload) => {
  const response = await httpPost(`${urlSchoolClassID(id)}`, {
    ...payload
  })
  return response
}

export const editTextReenroll = async (id, payload) => {
  const response = await httpPut(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.RE_ENROLLMENT}/${id}/text`,
    payload
  )

  return response
}

export const reenrollPdfSummary = async (id) => {
  const url = `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.RE_ENROLLMENT}/${id}/pdf-summary`

  const response = await httpGet(url)

  return response
}

export const getEditListDiscipline = async (params, id) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${
      ENDPOINT.SCHOOL_CLASS.DISCIPLINES
    }?${convertToParams(params)}`
  )
  return response
}

export const getListOptionsDisciplines = async (params) => {
  const response = await httpGet(`${ENDPOINT.SCHOOL_CLASS.DISCIPLINES}`, {
    ...convertToParams(params)
  })

  return response
}

export const getListDisciplinesJoy = async () => {
  const response = await httpGet(`${ENDPOINT.ADMIN_MANAGEMENT_JOY}`)

  return response
}

export const updateDetailsSchoolClassDiscipline = async (id, payload) => {
  const response = await httpPut(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${ENDPOINT.SCHOOL_CLASS.DISCIPLINES}`,
    payload
  )
  return response
}

export const deleteDisciplines = async (payload, id) => {
  const response = await httpDelete(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${ENDPOINT.SCHOOL_CLASS.DISCIPLINES}`,
    {
      ...payload
    }
  )
  return response
}

export const getEditListDisciplines = async (id, params) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${ENDPOINT.SCHOOL_CLASS.DISCIPLINES}`,
    {
      ...params
    }
  )
  return response
}

export const getCreateDisciplines = async (id, params) => {
  const response = await httpPost(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${ENDPOINT.SCHOOL_CLASS.DISCIPLINES}`,
    {
      ...params
    }
  )
  return response
}

export const createDisciplines = async ({ values: payload, id }) => {
  const response = await httpPost(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${ENDPOINT.SCHOOL_CLASS.DISCIPLINES}`,
    payload
  )

  return response
}

export const getBind = async (params, id) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${ENDPOINT.SCHOOL_CLASS.STUDENTS}/${
      ENDPOINT.SCHOOL_CLASS.BIND
    }?${convertToParams(params)}`
  )
  return response
}

export const getUnbinded = async (params, id) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${ENDPOINT.SCHOOL_CLASS.STUDENTS}/${
      ENDPOINT.SCHOOL_CLASS.UNBINDED
    }?${convertToParams(params)}`
  )
  return response
}

export const unbindedStudents = async (payload) => {
  const response = await httpDelete(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.UNBINDED_STUDENTS}`,
    payload
  )
  return response
}

export const bindStudents = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${ENDPOINT.SCHOOL_CLASS.BIND_STUDENTS}`,
    payload
  )
  return response
}

export const importIntegrateEvaluation = async (id, payload) => {
  const response = await httpPost(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${id}/${ENDPOINT.SCHOOL_CLASS.INTEGRATED_EVALUATION}`,
    payload
  )
  return response
}

export const getDashboardCompany = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.DASHBOARD_SCHOOL.BASE_URL}?${convertToParams(params)}`
  )

  return response
}

export const getDetailsDashClass = async (params, classId) => {
  const response = await httpGet(
    `${
      ENDPOINT.DASHBOARD_SCHOOL.BASE_URL
    }/school-class/${classId}/details?${convertToParams(params)}`
  )
  return response
}

export const getStudentDashClass = async (params, classId) => {
  const response = await httpGet(
    `${
      ENDPOINT.DASHBOARD_SCHOOL.BASE_URL
    }/school-class/${classId}?${convertToParams(params)}`
  )
  return response
}

export const getExportReport = async (params, classId) => {
  const response = await httpGet(
    `${
      ENDPOINT.DASHBOARD_SCHOOL.BASE_URL
    }/school-class/${classId}/xls?${convertToParams(params)}`,
    {
      responseType: 'blob'
    }
  )
  return response
}

export const sendDashStudentEmail = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.DASHBOARD_SCHOOL.BASE_URL_EMAIL}/student`,
    {
      ...payload
    }
  )
  return response
}

export const getFrequenciesToPDF = async ({ classId, classDisciplineId }) => {
  const response = await httpGet(
    `${PROFESSOR_ENDPOINT.PROFESSOR_DIARY_FREQUENCIES}/pdf`,
    {
      params: { classId, classDisciplineId }
    },
    'v2'
  )
  return response
}
export const getScoreToPDF = async ({ classId, classDisciplineId }) => {
  const response = await httpGet(
    `${PROFESSOR_ENDPOINT.PROFESSOR_DIARY_SCORES}/pdf`,
    {
      params: { classId, classDisciplineId }
    },
    'v1'
  )
  return response
}
export const getPartialReportToPDF = async ({
  classId,
  startDate,
  endDate,
  onlyActives
}) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${classId}/partial-report`,
    {
      params: { startDate, endDate, onlyActives }
    },
    'v1'
  )
  return response
}
export const getSchoolReportToPDF = async ({ classId, onlyActives }) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${classId}/report-card/pdf`,
    {
      params: { onlyActives }
    },
    'v1'
  )
  return response
}

export const getSchoolReportToXLSX = async ({ classId, onlyActives }) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.CRUD}/${classId}/report-card/xlsx`,
    {
      params: { onlyActives }
    },
    'v1'
  )
  return response
}

export const getBimestersByUnit = async (unit, params) => {
  const path = `${ENDPOINT.SCHOOL_CLASS.SCORES}/${unit}/bimonthly-dates`

  const response = await httpGet(path, {
    params
  })

  return response
}
