import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { buildTableFooter } from './Footer'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export function buildTable({
  gradeDetails,
  classInfo,
  subjects,
  withSignature
}) {
  const classTimeMS = 45
  const amountMinutosIn1Hour = 60
  const factor = classTimeMS / amountMinutosIn1Hour

  return [
    {
      table: {
        body: [
          [
            {
              text: 'Unidades Curriculares',
              style: ['tableHeaderCell']
            },
            {
              text: 'Carga Horária',
              style: ['tableHeaderCell'],
              alignment: 'center'
            },
            {
              text: 'Média Final',
              style: ['tableHeaderCell'],
              alignment: 'center'
            }
          ],
          ...gradeDetails.map((gradeDetail) => {
            const { grade, discipline } = gradeDetail
            return [
              { text: discipline.name },
              { text: discipline.workload, alignment: 'center' },
              {
                text: grade.finalGrade.toFixed(2).replace('.', ','),
                alignment: 'center'
              }
            ]
          }),
          [
            {
              text: 'Carga horária total (h/a)',
              style: ['tableHeaderCell']
            },
            {
              text: gradeDetails.reduce(
                (acc, current) => acc + current.discipline.workload,
                0
              ),
              colSpan: 2,
              style: ['tableHeaderCell'],
              alignment: 'center'
            },
            {}
          ],
          [
            {
              text: 'Carga horária total (h)',
              style: ['tableHeaderCell']
            },
            {
              text:
                gradeDetails.reduce(
                  (acc, current) => acc + current.discipline.workload,
                  0
                ) * factor,
              colSpan: 2,
              style: ['tableHeaderCell'],
              alignment: 'center'
            },
            {}
          ],
          [
            {
              text: 'Resultado Final',
              style: ['tableHeaderCell']
            },
            {
              text: gradeDetails.every(
                (gradeDetail) => gradeDetail.student.approved
              )
                ? 'Aprovado'
                : 'Reprovado',
              colSpan: 2,
              style: ['tableHeaderCell'],
              alignment: 'center'
            },
            {}
          ]
        ],
        widths: ['*', 80, 80]
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    },
    buildTableFooter({ subjects, classInfo, withSignature })
  ]
}
