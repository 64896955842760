import React, { lazy, useState } from 'react'
import { TableContainer, Table as TableMain, Pagination } from '@mui/material'
import { MilitaryTech } from '@mui/icons-material'
import { getComparator, stableSort, IsEmpty } from 'services/helpers'
import { MATOGROSSO_UNIT, NOVOTEC_UNIT } from 'services/constants/units'
import { noScoresFoundText, alternativeText } from './message'
import { sortAsc, sortDesc, typeAll, defaultTotalPage } from './constants'
import { StyledPaper, StyledPagination } from './style'

const TableFooter = lazy(() => import('./TableFooter'))
const TableHeader = lazy(() => import('./TableHeader'))
const TextNotFound = lazy(() => import('components/atoms/TextNotFound'))

const Table = ({
  scores,
  handleClickScore,
  totalPages,
  activePage,
  handleSetPage,
  type,
  unit,
  shouldShowScores
}) => {
  const [order, setOrder] = useState(sortAsc)
  const [orderBy, setOrderBy] = useState()
  const shouldshowExFinal = [NOVOTEC_UNIT, MATOGROSSO_UNIT].includes(unit)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }
  const isMessageType = type !== typeAll ? ` ${type}` : ''

  return (
    <>
      {scores && !IsEmpty(scores) && !shouldShowScores ? (
        <>
          <StyledPaper>
            <TableContainer>
              <TableMain aria-labelledby="tableTitle" size="medium">
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  shouldshowExFinal={shouldshowExFinal}
                />

                {stableSort(scores, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = row.subjec
                    const labelId = `enhanced-table-${index}`
                    return (
                      <TableFooter
                        key={labelId}
                        labelId={labelId}
                        row={row}
                        isItemSelected={isItemSelected}
                        handleClickScore={handleClickScore}
                        shouldshowExFinal={shouldshowExFinal}
                      />
                    )
                  }
                )}
              </TableMain>
            </TableContainer>
          </StyledPaper>
          {!IsEmpty(scores) && totalPages > defaultTotalPage && (
            <StyledPagination>
              <Pagination
                count={totalPages}
                page={activePage}
                onChange={handleSetPage}
              />
            </StyledPagination>
          )}
        </>
      ) : (
        <TextNotFound
          bold
          text={
            !shouldShowScores
              ? noScoresFoundText(isMessageType)
              : alternativeText
          }
          icon={<MilitaryTech color="gray" sx={{ fontSize: 50 }} />}
        />
      )}
    </>
  )
}
export default Table
