import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { buildTableHeader } from './Header'
import { buildTableBody } from './Body'
import { buildTableFooter } from './Footer'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export function buildTable({ classInfo, gradeDetails, courseInfo, subjects }) {
  return [
    {
      table: {
        body: [
          [
            {
              text: courseInfo.resolutionLevel,
              border: [true, false, true, true]
            }
          ]
        ],
        widths: '*'
      },
      layout: {
        ...borderLayoutDefault,
        paddingBottom: () => 3,
        paddingLeft: () => 3,
        paddingRight: () => 3,
        paddingTop: () => 3
      }
    },
    {
      table: {
        body: [
          buildTableHeader(),
          ...buildTableBody({ gradeDetails, subjects }),
          ...buildTableFooter({ courseInfo })
        ],
        widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto']
      },
      layout: {
        ...borderLayoutDefault,
        paddingBottom: () => 3,
        paddingLeft: () => 3,
        paddingRight: () => 3,
        paddingTop: () => 3
      },
      margin: [0, 0, 0, 20]
    }
  ]
}
