import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TemplateStudentMyScores as Template } from 'components/templates/Student'
import { Snackbar } from 'components/molecules'
import { PageTitle } from 'components/atoms'
import { getUserFromLocalStorage } from 'services/helpers'
import { useSnack } from 'services/hooks'
import { Content, ModalInformative, ModalResendCode } from './components'
import {
  getMessagesModal,
  nameTypeContract,
  typeModal,
  payloadSkipSignature
} from './constants'
import {
  confirmSignature,
  contractGenerate,
  resendCode,
  signatureSkip
} from './helpers'
import * as Styled from './style'

const Contract = () => {
  const { username } = getUserFromLocalStorage()
  const [modal, setModal] = useState('')
  const [code, setCode] = useState('')
  const [contractInfo, setContractInfo] = useState('')
  const { snack, snackOpen, setSnack } = useSnack()
  const navigate = useNavigate()

  const handleCloseModal = () => setModal('')

  const handleResendCodeModal = () => setModal(typeModal.resendCode)

  const redirectHome = () => {
    navigate('/student/hub', {
      state: { savedSearch: true }
    })
  }

  const handleConfirmAssignature = () => {
    confirmSignature({
      setSnack,
      callbackSuccess: () => {
        setModal(typeModal.signedContract)
        redirectHome()
      },
      callbackError: () => setModal(typeModal.invalidCode),
      contract: contractInfo?.course_id,
      code
    })
  }

  const handleResendCode = (payload) => {
    resendCode({
      payload,
      setSnack,
      callbackSuccess: handleCloseModal,
      contract: contractInfo?.course_id
    })
  }

  const handleSkipAssignature = () => {
    signatureSkip({
      payloadSkipSignature,
      setSnack,
      contract: contractInfo?.course_id,
      redirectHome,
      navigate
    })
  }

  const messagesModal = getMessagesModal({
    handleResendCode,
    handleCloseModal
  })[modal]

  useEffect(() => {
    contractGenerate({ setSnack, username, setContractInfo })
  }, [])

  return (
    <Template
      titleHeader={
        <PageTitle
          title={`<b>${nameTypeContract} - #${contractInfo?.course_id} - ${contractInfo?.course} </b>`}
          divider
        />
      }
      content={
        <Styled.Container>
          <Content
            email={contractInfo?.email}
            filepath={contractInfo?.contract_pdf_base64}
            cellphone={contractInfo?.telephone}
            expireDays={contractInfo?.skip_contract_days}
            skipContractDays={contractInfo?.skip_contract_days}
            skippedClickedAt={contractInfo?.skipped_clicked_at}
            handleResendCode={handleResendCodeModal}
            handleConfirmAssignature={handleConfirmAssignature}
            handleSkipAssignature={handleSkipAssignature}
            code={code}
            setCode={setCode}
          />

          {[typeModal.invalidCode, typeModal.signedContract].includes(
            modal
          ) && (
            <ModalInformative
              fullWidth
              maxWidth="sm"
              open={!!modal}
              handleClose={handleCloseModal}
              {...messagesModal}
            />
          )}

          {modal === typeModal.resendCode && (
            <ModalResendCode
              maxWidth="sm"
              open={!!modal}
              handleClose={handleCloseModal}
              telephoneContract={contractInfo?.telephone}
              emailContract={contractInfo?.email}
              {...messagesModal}
            />
          )}
        </Styled.Container>
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default Contract
