export const STUDENT_ENDPOINT = {
  STUDENT: 'students',
  STUDENT_DOCUMENTS: 'documents',
  STUDENT_DOWNLOAD_PDF_DECREES: 'documents/declaration',
  STUDENT_UPLOAD_PDF_DOCS: 'documents/upload',
  STUDENT_LIST_DECLARATION_CLASSES: 'documents/request/declaration',
  STUDENT_LIST_APPLICATION_CLASSES: 'documents/request/requirement ',
  STUDENT_LIST_TYPES_DECLARATION_REQUIREMENTS: 'documents/request',
  STUDENT_DECREES_REQUEST: 'documents/request',
  STUDENT_FINANCES_REGISTER: 'finances/student?register=',
  STUDENT_FINANCES: 'finances/student',
  STUDENT_FINANCES_HAS_OVERDUE: ({ register }) =>
    `finances/student/${register}/has-overdue`,
  STUDENT_SUBJECTS: 'subjects',
  STUDENT_FINANCES_OVERDUE:
    '&status=invoice_due&status=overdue&amount_overdue=3',
  STUDENT_FINANCES_NEXT_BILL: '&status=invoice_due',
  COMPLEMENTARY_ACTIVITY: 'complementary-activity',
  COMPLEMENTARY_ACTIVITY_TYPES: 'complementary-activity/types/school-class',
  COMPLEMENTARY_ACTIVITY_CREATE: 'complementary-activity/student/request',
  COMPLEMENTARY_ACTIVITY_RATE: 'complementary-activity',
  STUDENT_COURSE_AND_CLASS: (registerId) =>
    `student-management/secretary/${registerId}/school-class/courses`,
  RE_ENROLLMENT: {
    DETAILS: 'enrollments/re-enrollment'
  },
  CONTRACT: 'contracts',
  CONTRACT_GENERATE: 'contracts/generate',
  UPDATE_CONTACT: 'contracts/student-info',
  ACCEPTE_CONTRACT: 'contracts/validate-signature',
  SKIP_CONTRACT: 'contracts/skip-signature',
  STUDENT_PAYMENT_METHODS: (studentId) =>
    `students/${studentId}/payment-methods`,
  STUDENT_UNIT_INFO: 'units/student',
  ACADEMIC_REPORT: 'academic-report'
}
