import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { buildTableHeader } from './Header'
import { buildTableBody } from './Body'
import { buildTableFooter } from './Footer'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export function buildAcademicReportGrades({
  gradeDetails,
  courseInfo,
  subjects,
  classInfo,
  withSignature
}) {
  return {
    table: {
      body: [
        buildTableHeader(),
        ...buildTableBody({ gradeDetails, subjects, classInfo, courseInfo }),
        ...buildTableFooter({ classInfo, withSignature })
      ],
      widths: [25, 20, '*', 55, 40, 25, 40]
    },
    layout: {
      ...borderLayoutDefault,
      paddingBottom: () => 2,
      paddingLeft: () => 2,
      paddingRight: () => 2,
      paddingTop: () => 2
    }
  }
}
