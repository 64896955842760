export const dateStringFormat = 'YYYYMMDDHHmmss'

export const DATE_FORMAT = {
  DD_MM_YYYY: 'DD/MM/YYYY',
  MM_YYYY: 'MM/YYYY',
  YYYY_MM_DD_HIFEN: 'YYYY-MM-DD',
  DD: 'DD',
  MM: 'MM'
}

export const millisecondDayConversionFactor = 86400000
