import PropTypes from 'prop-types'

import { Unstable_Grid2 as Grid } from '@mui/material'
import Counter from 'components/atoms/Counter'
import { useCountDown } from 'services/hooks'
import { Footer, Header, Separator, Message } from './styles'

const WarningCountDown = (props) => {
  const {
    backgroundColor,
    header,
    deadline,
    footer,
    displayClock,
    message,
    displaySeconds
  } = props

  const [days, hours, minutes, seconds] = useCountDown(deadline)

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        backgroundColor,
        padding: '10px 0px 0px 0px',
        gap: '15px',
        borderRadius: '12px',
        opacity: '0px'
      }}
    >
      <Grid xs={12} alignItems="center" justifyContent="center">
        <Header>{header}</Header>
      </Grid>
      {displayClock === true && (
        <Grid
          xs={12}
          alignItems="center"
          justifyContent="center"
          sx={{
            display: 'flex'
          }}
        >
          <Counter
            fontColor={backgroundColor}
            title="Dias"
            number={days || 0}
          />
          <Separator>:</Separator>
          <Counter
            fontColor={backgroundColor}
            title="Horas "
            number={hours || 0}
          />
          <Separator>:</Separator>
          <Counter
            fontColor={backgroundColor}
            title="Minutos"
            number={minutes || 0}
          />
          {displaySeconds && (
            <>
              <Separator>:</Separator>
              <Counter
                fontColor={backgroundColor}
                title="Segundos"
                number={seconds || 0}
              />
            </>
          )}
        </Grid>
      )}
      {message && (
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Message>{message}</Message>
        </Grid>
      )}

      <Grid>
        <Footer> {footer}</Footer>
      </Grid>
    </Grid>
  )
}

export default WarningCountDown

WarningCountDown.propTypes = {
  backgroundColor: PropTypes.string,
  header: PropTypes.string,
  deadline: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  displayClock: PropTypes.bool,
  message: PropTypes.string,
  displaySeconds: PropTypes.bool
}
