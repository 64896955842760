import humps from 'humps'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getProfessorsByDisciplines } from 'services/api/professor'
import { updateErrorMessage } from 'services/helpers'
import { getBimestersByUnit } from 'services/api/admin'

export async function listProfessorsByDisciplines({ disciplinesIds, onError }) {
  const { data, error, status } = await getProfessorsByDisciplines({
    disciplinesIds
  })

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDisciplines
    })
  }

  return humps.camelizeKeys(data)
}

export const listBimestersByUnit = async ({
  unit,
  year,
  onSuccess,
  onError
}) => {
  const { data, error, status } = await getBimestersByUnit(unit, {
    year
  })

  if (error) {
    return onError?.({ error, status })
  }

  return onSuccess(data)
}
