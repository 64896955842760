import buildSignature from 'components/templates/PDF/shared/BasePage/Signature'
import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { clone } from 'services/helpers'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export function buildTableFooter({ subjects, classInfo, withSignature }) {
  const year = new Date(subjects[0].beginAt).getFullYear()

  const signature = buildSignature(
    classInfo.signatures,
    classInfo.poleCity || classInfo.unitCity,
    withSignature
  )

  return {
    table: {
      body: [
        [{ text: 'Observações', bold: true, colSpan: 3 }, {}, {}],
        [
          {
            text: 'Unidades Curriculares',
            style: ['tableHeaderCell']
          },
          {
            text: `Temáticas do 1º semestre/ ${year}`,
            style: ['tableHeaderCell']
          },
          {
            text: `Temáticas do 2º semestre/ ${year}`,
            style: ['tableHeaderCell']
          }
        ],
        ...subjects.map((subject) => {
          const sortedSubject = subject.subjects.sort((a, b) =>
            new Date(a.beginAt).getTime() > new Date(b.beginAt).getTime()
              ? 1
              : -1
          )

          const [subjectDeep1, subjectDeep2] = sortedSubject
          return [
            {
              text: subject.name
            },
            {
              text: subjectDeep1.name
            },
            {
              text: subjectDeep2.name
            }
          ]
        }),
        [{ stack: [...clone(signature)], colSpan: 3 }, {}, {}]
      ],
      widths: [100, '*', '*']
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}
