import * as yup from 'yup'

export default function getSchema() {
  const requiredMessage = 'Campo obrigatório'

  const schema = yup.object().shape({
    year: yup.number().nullable().required(requiredMessage),
    bimester: yup
      .object()
      .nullable()
      .when('year', {
        is: (value) => value && value >= 2024,
        then: (currentSchema) => currentSchema.required(requiredMessage)
      })
  })

  return schema
}
