import React from 'react'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { getClassInfo } from 'components/pages/Admin/HumanResources/Professor/CreateByUpdate/components/ReplaceClassDetailsEdit/services'

const initialState = {
  classDetails: {
    id: null,
    name: '',
    code: '',
    shift: '',
    unit: '',
    level: '',
    group: '',
    modality_id: null,
    modality: '',
    lesson_duration: null,
    grade_year: '',
    begin_at: '',
    end_at: '',
    company_id: null,
    type_frequency: '',
    minimum_frequency: null,
    by_subject: null,
    stage: { id: null, name: '', type: null, sum_module: null }
  },
  loadClassDetails: (classId) => {}
}

const DiaryGradesContext = React.createContext(initialState)

export function DiaryGradesProvider({ children }) {
  const [classDetails, setClassDetails] = React.useState(
    initialState.classDetails
  )

  const { setLoading } = useLoading()

  const loadClassDetails = async (classId) => {
    setLoading(true)
    const classInfo = await getClassInfo({
      id: classId,
      onError: () => setLoading(false)
    })

    if (classInfo) {
      setClassDetails(classInfo)
    }
    setLoading(false)
  }

  return (
    <DiaryGradesContext.Provider value={{ classDetails, loadClassDetails }}>
      {children}
    </DiaryGradesContext.Provider>
  )
}

export const useDiaryGradesContext = () => React.useContext(DiaryGradesContext)
