import { utilsPDF } from 'components/templates/PDF/shared/utils'
import htmlToPdfmake from 'html-to-pdfmake'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export function buildProfessionalProfile({ courseInfo }) {
  return {
    pageBreak: 'before',
    table: {
      body: [
        [
          {
            text: 'Perfil Profissional de Conclusão da Qualificação Profissional',
            colSpan: 2,
            bold: true,
            alignment: 'center',
            fontSize: 11
          },
          {}
        ],
        [
          {
            stack: [
              htmlToPdfmake(courseInfo.curriculumStructureResolution),
              htmlToPdfmake(courseInfo.curriculumStructureComplement)
            ],
            colSpan: 2
          },
          {}
        ]
      ],
      widths: '*'
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}
