import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  height: 100vh;
  padding-top: 64px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 4rem;
  margin-left: -16px;
`
const HeaderButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 4rem;
`

const Body = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  padding: 24px;
  position: relative;
`

export { Container, Header, HeaderButton, Body }
