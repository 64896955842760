import { UNITS } from 'services/constants'
import { isNil } from 'services/helpers'
import theme from 'theme/designTokens'

const borderLayoutDefault = {
  hLineColor: () => theme.palette.pdf.borderLigth,
  vLineColor: () => theme.palette.pdf.borderLigth,
  hLineWidth: () => 0.1,
  vLineWidth: () => 0.1
}

const paddingLayoutDefault = {
  paddingBottom: () => 5,
  paddingLeft: () => 5,
  paddingRight: () => 5,
  paddingTop: () => 5
}

const noBorderOnTop = [true, false, true, true]
const noBorder = [false, false, false, false]

const chunkArray = (array, chunkSize) => {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

const getIsLikeNovotec = (unit) =>
  [UNITS.NOVOTEC.name, UNITS.MS.name].includes(unit)
const getIsFrom2024 = (date) => new Date(date).getFullYear() >= 2024

const logoProzSVG = `
<svg width="146" height="44" viewBox="0 0 146 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M69.2861 16.754C69.2861 27.4148 65.3813 29.5799 53.8215 29.5799H47.2829V42.4928C47.2829 43.179 47.1234 43.3385 46.4903 43.3385H39.7874C39.1543 43.3385 38.9997 43.179 38.9997 42.4928V4.51279C38.9997 3.93287 39.1543 3.82655 39.7874 3.77339C44.4316 3.35295 49.5011 3.19348 53.8263 3.19348C65.3813 3.19348 69.2909 6.19939 69.2909 16.754H69.2861ZM47.2877 10.4232V23.1911H52.9855C59.2679 23.1911 60.9014 22.3454 60.9014 16.9666C60.9014 11.6893 59.2679 10.4232 52.9855 10.4232H47.2877Z" fill="#593493"/>
<path d="M88.5733 14.1056C89.1532 14.2651 89.419 14.4729 89.419 15.1591V19.5085C89.419 20.0353 89.1 20.2963 88.5201 20.2963H83.5618C80.8168 20.2963 79.8696 20.7215 79.8696 22.9881V42.5121C79.8696 43.0968 79.6038 43.3578 79.0771 43.3578H72.7946C72.2678 43.3578 72.0069 43.0968 72.0069 42.5121V20.9632C72.0069 14.3134 76.7526 13.5257 81.5562 13.5257C83.0882 13.5257 86.7272 13.6803 88.5733 14.1056Z" fill="#593493"/>
<path d="M118.178 28.2991C118.178 40.3325 115.699 43.3384 104.724 43.3384C93.7491 43.3384 91.2651 40.3325 91.2651 28.2991C91.2651 16.2657 93.8022 13.2598 104.724 13.2598C115.646 13.2598 118.178 16.2174 118.178 28.2991ZM99.1278 28.2991C99.1278 34.9489 99.9204 36.5823 104.724 36.5823C109.528 36.5823 110.369 34.9489 110.369 28.2991C110.369 21.6493 109.576 20.0159 104.724 20.0159C99.8721 20.0159 99.1278 21.63 99.1278 28.2991" fill="#593493"/>
<path d="M144.42 13.7867C145 13.7867 145.319 14.0525 145.319 14.5792V18.1699C145.309 18.5354 145.178 18.8872 144.946 19.1703L131.966 36.5679H145.106C145.686 36.5679 146 36.8337 146 37.3605V42.4783C146 43.063 145.686 43.324 145.106 43.324H121.571C120.938 43.324 120.604 43.063 120.604 42.4783V39.0519C120.607 38.6837 120.739 38.328 120.976 38.0467L134.377 20.3833H122.151C121.518 20.3833 121.252 20.1223 121.252 19.5907V14.5792C121.252 14.0525 121.518 13.7867 122.151 13.7867H144.42Z" fill="#593493"/>
<path d="M16.6196 0.129553L27.2901 6.29122C27.4424 6.37442 27.5694 6.49708 27.6579 6.64631C27.7464 6.79554 27.7931 6.96585 27.7931 7.13935C27.7931 7.31286 27.7464 7.48315 27.6579 7.63238C27.5694 7.78162 27.4424 7.90427 27.2901 7.98748L16.6196 14.1491C16.4716 14.2346 16.3037 14.2793 16.1329 14.2787C15.9621 14.2781 15.7944 14.2323 15.6471 14.1458C15.4998 14.0594 15.378 13.9354 15.2941 13.7866C15.2103 13.6378 15.1674 13.4694 15.1698 13.2986V0.980094C15.1674 0.80929 15.2103 0.640902 15.2941 0.492078C15.378 0.343254 15.4998 0.21931 15.6471 0.132872C15.7944 0.0464336 15.9621 0.000590335 16.1329 5.66218e-06C16.3037 -0.000579011 16.4716 0.0441259 16.6196 0.129553Z" fill="#593493"/>
<path d="M12.7776 28.8163H1.33382C1.07748 28.8163 0.831622 28.7145 0.650362 28.5332C0.469101 28.352 0.367285 28.1061 0.367285 27.8498V16.3963C0.358779 16.1984 0.411318 16.0026 0.517776 15.8355C0.624233 15.6684 0.779464 15.5381 0.962462 15.4621C1.14546 15.3862 1.34736 15.3683 1.54086 15.4109C1.73435 15.4536 1.91006 15.5546 2.04421 15.7004L13.4832 27.1394C13.6286 27.2733 13.7295 27.4485 13.7722 27.6415C13.8149 27.8345 13.7975 28.036 13.7222 28.2188C13.6468 28.4015 13.5173 28.5568 13.351 28.6636C13.1847 28.7705 12.9896 28.8238 12.7921 28.8163" fill="#FF7F00"/>
<path d="M12.7776 43.3385H1.33381C1.07747 43.3385 0.831616 43.2367 0.650355 43.0554C0.469095 42.8741 0.367279 42.6283 0.367279 42.3719V30.9137C0.367279 30.6573 0.469095 30.4115 0.650355 30.2302C0.831616 30.049 1.07747 29.9471 1.33381 29.9471H12.7776C13.0339 29.9471 13.2798 30.049 13.461 30.2302C13.6423 30.4115 13.7441 30.6573 13.7441 30.9137V42.3575C13.7441 42.6138 13.6423 42.8596 13.461 43.0409C13.2798 43.2221 13.0339 43.324 12.7776 43.324" fill="#FF7F00"/>
<path d="M7.05571 14.1926C5.66043 14.1926 4.29647 13.7789 3.13627 13.0038C1.97607 12.2288 1.07172 11.1271 0.537552 9.83811C0.00338061 8.54913 -0.136632 7.1307 0.135222 5.76216C0.407077 4.39362 1.0786 3.13642 2.06487 2.14947C3.05115 1.16252 4.3079 0.490133 5.67625 0.217341C7.04461 -0.0554505 8.46312 0.0835946 9.75247 0.616883C11.0418 1.15017 12.1441 2.05376 12.92 3.21343C13.6959 4.3731 14.1105 5.73678 14.1114 7.13206C14.1114 8.0584 13.9289 8.97566 13.5742 9.83143C13.2196 10.6872 12.6998 11.4647 12.0446 12.1195C11.3893 12.7743 10.6115 13.2935 9.75544 13.6476C8.89944 14.0017 7.98205 14.1836 7.05571 14.1829" fill="#FF7F00"/>
<path d="M15.9333 28.8163C19.1241 28.564 22.1202 27.1822 24.3834 24.9189C26.6467 22.6557 28.0286 19.6595 28.2808 16.4688C28.2937 16.3344 28.2782 16.1988 28.2354 16.0707C28.1926 15.9426 28.1234 15.8249 28.0324 15.7252C27.9413 15.6255 27.8303 15.546 27.7067 15.4918C27.583 15.4376 27.4493 15.4099 27.3143 15.4105H15.8609C15.6045 15.4105 15.3587 15.5123 15.1774 15.6936C14.9961 15.8748 14.8943 16.1207 14.8943 16.377V27.8208C14.8938 27.9554 14.9213 28.0886 14.9753 28.2119C15.0292 28.3352 15.1083 28.4459 15.2074 28.5369C15.3066 28.6279 15.4237 28.6972 15.5512 28.7403C15.6787 28.7834 15.8138 28.7994 15.9478 28.7873" fill="#FF7F00"/>
</svg>
`

const handleGrade = ({ grade, noGradeView = '0,0' }) => {
  if (!isNil(grade)) return grade.toFixed(2).replace('.', ',')
  return noGradeView
}

const addAsteristic = (value, count = 1) => `${'*'.repeat(count)}${value}`

const colors = {
  black: theme.palette.dark.main,
  green: theme.palette.success.main,
  red: theme.palette.red.main,
  grey: theme.palette.gray.main,
  yellowDark: theme.palette.yellow.warning,
  yellowLight: theme.palette.yellow.dark
}

const handlePartialGrade = ({ partialGrade, minimumGrade, noGradeView }) => {
  const belowAverage = partialGrade < minimumGrade
  const grade = handleGrade({ grade: partialGrade, noGradeView })
  return {
    grade: belowAverage ? addAsteristic(grade) : grade,
    color: belowAverage ? colors.red : colors.black
  }
}

const handleFinalGrade = ({
  finalGrade,
  hasAcademicPerformance,
  noGradeView,
  minimumGrade
}) => {
  const belowAverage = finalGrade < minimumGrade

  const grade = handleGrade({ grade: finalGrade, noGradeView })
  if (belowAverage && !hasAcademicPerformance) {
    return addAsteristic(grade, 1)
  }
  return hasAcademicPerformance ? addAsteristic(grade, 2) : grade
}

const labelNumber = {
  0: { value: 0, label: 'zero' },
  1: { value: 1, label: 'um' },
  2: { value: 2, label: 'dois' },
  3: { value: 3, label: 'três' },
  4: { value: 4, label: 'quatro' },
  5: { value: 5, label: 'cinco' },
  6: { value: 6, label: 'seis' },
  7: { value: 7, label: 'sete' },
  8: { value: 8, label: 'oito' },
  9: { value: 9, label: 'nove' },
  10: { value: 10, label: 'dez' }
}

export const utilsPDF = {
  borderLayoutDefault,
  paddingLayoutDefault,
  noBorderOnTop,
  noBorder,
  chunkArray,
  logoProzSVG,
  getIsLikeNovotec,
  getIsFrom2024,
  handlePartialGrade,
  handleFinalGrade,
  colors,
  addAsteristic,
  handleGrade,
  labelNumber
}
