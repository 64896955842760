import { createContext, useContext } from 'react'
import { initializeApp } from 'firebase/app'
import {
  getAnalytics,
  logEvent as logEventAnalitcs,
  // setUserProperties as setUserPropertiesAnalytics,
  setUserId as setUserIdAnalytics
} from 'firebase/analytics'
import { getUserFromLocalStorage } from 'services/helpers'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY?.toString(),
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN?.toString(),
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID?.toString(),
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET?.toString(),
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID?.toString(),
  appId: process.env.REACT_APP_FIREBASE_APP_ID?.toString(),
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID?.toString()
}

const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)

const INITIAL_STATE = {
  logEvent: (eventName) => {},
  setUserId: (userId) => {}
  // setUserProperties: (properties, options) => {},
}

const AnalyticsContext = createContext(INITIAL_STATE)

export function AnalyticsProvider({ children }) {
  const isProd = process.env.REACT_APP_ENV === 'prod'

  const logEvent = (eventName, eventparams = {}) => {
    const user = getUserFromLocalStorage()
    const role = user?.role

    if (!isProd) return

    if (eventName) {
      logEventAnalitcs(analytics, eventName, {
        ...(role ? { role } : {}),
        ...eventparams
      })
    }
  }

  const setUserId = (userId) => {
    if (!isProd) return

    if (userId) {
      setUserIdAnalytics(analytics, userId)
    }
  }

  // const setUserProperties = (properties, options = {}) => {
  //   if (properties && Object.keys(properties).length) {
  //     setUserPropertiesAnalytics(analytics, properties, options)
  //   }
  // }

  return (
    <AnalyticsContext.Provider
      value={{
        logEvent,
        // setUserProperties,
        setUserId
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => useContext(AnalyticsContext)
