import { Person } from '@mui/icons-material'
import { Notifications, Profile } from 'components/pages/Shared'
import { ACL } from 'services/constants/acl'
import { ROUTE } from 'services/constants'

import theme from 'theme/designTokens'
import { getUserFromLocalStorage } from 'services/helpers'
import { USER_EVENTS } from 'events/user'

export const iconProps = {
  color: theme.palette.primary.white
}

const USER_ROUTES = [
  {
    component: Profile,
    exact: true,
    icon: <Person sx={{ ...iconProps }} />,
    label: 'Perfil',
    path: ROUTE.USER,
    permissions: ACL.all,
    isAllowed: () => {
      const { role } = getUserFromLocalStorage()

      return Boolean(role !== ACL.admin.name)
    },
    eventName: USER_EVENTS.sidebar.student.btn.openProfile
  },
  {
    component: Notifications,
    exact: true,
    path: ROUTE.NOTIFICATION,
    permissions: ACL.all,
    isAllowed: () => true
  }
]

export default USER_ROUTES
