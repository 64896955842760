import React from 'react'
import * as Styled from './style'

const TableHeader = ({ headCells, dropouts, hiddeColumns }) => (
  <Styled.TableHead $dropouts={!dropouts}>
    <Styled.TableRow>
      {headCells.map((headCell) => {
        if (Array.isArray(hiddeColumns) && hiddeColumns.includes(headCell.id)) {
          return null
        }

        return (
          <Styled.TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding="normal"
          >
            {headCell.label}
          </Styled.TableCell>
        )
      })}
    </Styled.TableRow>
  </Styled.TableHead>
)

export default TableHeader
