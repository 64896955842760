import { useEffect, useRef, useState } from 'react'
import {
  Card,
  Divider,
  Unstable_Grid2 as Grid,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip,
  Button
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { convertDateToUTC, getUserFromLocalStorage } from 'services/helpers'
import { useAnalytics } from 'Context/AnalyticsContext'
import { STUDENT_EVENTS } from 'events/student'
import { useStudentFinancialContext } from '../../context'

import * as Style from './styles'

export default function ManagePayments() {
  const { username } = getUserFromLocalStorage()
  const {
    loadStudentPaymentMethods,
    studentPaymentMethods,
    openCheckoutToChangeCreditcard
  } = useStudentFinancialContext()
  const getNetButtonRef = useRef(null)

  useEffect(() => {
    loadStudentPaymentMethods({ register: username })
  }, [])

  const getChip = (active) => {
    if (active) {
      return <Chip label="Ativo" color="success" sx={{ color: 'white' }} />
    }
    return <Chip label="Inativo" />
  }

  const getPaymentMethod = (recurrence) => {
    switch (!!recurrence) {
      case true:
        return 'Recorrência no Cartão de Crédito'
      default:
        return 'Boleto'
    }
  }

  const shouldShowActions = (paymentMethod) => {
    if (paymentMethod.active && paymentMethod.recurrence) {
      return true
    }
    return false
  }

  return (
    <>
      <Grid container spacing={3}>
        {studentPaymentMethods.map((studentPaymentMethod) => (
          <Grid xs={12} key={studentPaymentMethod.contractId}>
            <Card>
              <Style.CardContent>
                <Grid container spacing={1} alignItems="center" mb={1}>
                  <Grid xs={8}>
                    <Typography fontSize={14} fontWeight="bold">
                      {studentPaymentMethod.course}
                    </Typography>
                  </Grid>
                  <Grid xs={4} display="flex" justifyContent="center">
                    {getChip(studentPaymentMethod.active)}
                  </Grid>
                </Grid>

                <Typography fontSize={12} mb={1}>
                  <b>Meio de Pagamento:</b>{' '}
                  {getPaymentMethod(studentPaymentMethod.recurrence)}
                </Typography>
                <Typography fontSize={12} mb={1}>
                  <b>Contratação:</b>{' '}
                  {convertDateToUTC(
                    studentPaymentMethod.registrationDate
                  ).toLocaleDateString('pt-BR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  })}
                </Typography>
                {!!studentPaymentMethod.active &&
                  !!studentPaymentMethod.nextCharge && (
                    <Typography fontSize={12} mb={1}>
                      <b>Próxima cobrança:</b>{' '}
                      {convertDateToUTC(
                        studentPaymentMethod.nextCharge
                      ).toLocaleDateString('pt-BR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      })}
                    </Typography>
                  )}
                {shouldShowActions(studentPaymentMethod) && (
                  <>
                    <Divider />
                    <ListActions
                      canChangeCreditCard={studentPaymentMethod.active}
                      openCheckoutToChangeCreditcard={() =>
                        openCheckoutToChangeCreditcard({
                          buttonRef: getNetButtonRef,
                          contractId: studentPaymentMethod.contractId,
                          register: username,
                          getnetSubscriptionId:
                            studentPaymentMethod.getnetSubscriptionId
                        })
                      }
                    />
                  </>
                )}
              </Style.CardContent>
            </Card>
            <Button
              ref={getNetButtonRef}
              className={`change-credit-card-button-getnet-${studentPaymentMethod.contractId}`}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

function ListActions({ openCheckoutToChangeCreditcard, canChangeCreditCard }) {
  const analytics = useAnalytics()

  return (
    <>
      <List>
        {!!canChangeCreditCard && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                analytics.logEvent(
                  STUDENT_EVENTS.financial.managePayments.openChangeCard
                )
                openCheckoutToChangeCreditcard()
              }}
            >
              <ListItemText>
                <Typography fontWeight={600} fontSize={14}>
                  Alterar Cartão de Crédito
                </Typography>
              </ListItemText>
              <ListItemIcon>
                <ArrowForwardIosIcon fontSize="14px" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </>
  )
}
