/* eslint-disable no-useless-escape */
import { UNITS } from 'services/constants'
import * as yup from 'yup'

const createHandledRequirementScheme = (courses, message) =>
  yup
    .string()
    .nullable()
    .when('course_id', (courseId, scheme) => {
      const course = courses.find((c) => c.value === courseId)
      const isMS = UNITS.MS.id === course.unit_id

      if (isMS) return scheme

      return scheme.required(message)
    })

const moutScheme = (courses) =>
  yup.object().shape({
    nationality: createHandledRequirementScheme(
      courses,
      'Nacionalidade é obrigatório'
    ),
    place_of_birth_state: createHandledRequirementScheme(
      courses,
      'Naturalidade (UF) é obrigatório'
    ),
    place_of_birth_city: createHandledRequirementScheme(
      courses,
      'Naturalidade (Cidade) é obrigatório'
    ),
    cpf: yup.string().nullable().required('CPF é obrigatório'),
    birth_date: yup
      .date()
      .nullable()
      .required('Data de Nascimento é obrigatório'),
    mother: createHandledRequirementScheme(courses, 'Nome da mãe é obrigatório')
  })

export default moutScheme
