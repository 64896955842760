import { useFormik } from 'formik'
import { Grid, Typography } from '@mui/material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { useSnack } from 'services/hooks'
import {
  formatDateEnv,
  getEndDateOfYear,
  getErrorMessage,
  getYearsBetweenDates,
  updateErrorMessage
} from 'services/helpers'
import { ButtonFooterV2, Select } from 'components/molecules'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { useEffect, useMemo, useState } from 'react'
import { listBimestersByUnit } from 'components/organisms/Diary/services'
import { ERROR, UNITS } from 'services/constants'
import { openPartialReportPDF } from '../../services'
import { useClassDetailsContext } from '../../context'
import { bimesterMapper, initialValues } from './constants'
import getSchema from './schema'
import * as Styled from './style'

export function FormReportPartialLikeNovotec({ handleClose }) {
  const { unitId, classId, classDetails } = useClassDetailsContext()
  const { setLoading } = useLoading()
  const { setSnack } = useSnack()
  const [bimesterOptions, setBimesterOptions] = useState([])

  const yearsOptions = useMemo(() => {
    if (classDetails) {
      const years = getYearsBetweenDates(
        classDetails.begin_at,
        classDetails.end_at
      )
      const formattedYears = years.map((year) => ({
        label: year,
        value: year
      }))

      return formattedYears
    }

    return []
  }, [classDetails])

  const onSubmit = ({ year, bimester }) => {
    setLoading(true)

    const isAClassWithBimester = year >= 2024
    const firsDateOfYear = new Date(year, 0, 1)

    return openPartialReportPDF({
      classId,
      startDate: isAClassWithBimester
        ? bimester.startDate
        : formatDateEnv(firsDateOfYear),
      endDate: isAClassWithBimester
        ? bimester.endDate
        : formatDateEnv(getEndDateOfYear(firsDateOfYear)),
      onError: ({ error, status }) => {
        setLoading(false)
        updateErrorMessage({
          setSnack,
          error,
          status,
          feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToPartialReport
        })
      },
      onFinish: () => setLoading(false)
    })
  }

  const formik = useFormik({
    onSubmit,
    enableReinitialize: true,
    initialValues,
    validationSchema: getSchema()
  })

  const isAClassWithBimester = formik.values.year >= 2024

  useEffect(() => {
    if (isAClassWithBimester) {
      const isNovoTech = UNITS.NOVOTEC.id === +unitId

      listBimestersByUnit({
        unit: isNovoTech ? 'novotec' : 'ms',
        year: formik.values.year,
        onSuccess: (data) => {
          const bimesterEntries = Object.entries(data)
          const options = bimesterEntries.map(([bimester, period]) => ({
            label: bimesterMapper[bimester],
            value: period
          }))

          setBimesterOptions(options)
        },
        onError: (error) => {
          const message = getErrorMessage(...error)
          setSnack(message, ERROR)
        }
      })
    }
  }, [formik.values.year])

  return (
    <Styled.Form
      component="form"
      noValidate
      autoComplete="off"
      container
      rowSpacing="20px"
    >
      <Grid container item xs={12} spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12}>
          <Typography variant="body2">
            Escolha o período que deseja gerar a ata:
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Select
            id="year"
            label="Ano"
            value={formik.values.year}
            helperText={formik.errors.year}
            error={formik.errors.year}
            required
            optionsList={yearsOptions}
            onChange={(e) => {
              formik.setFieldValue('bimester', null)
              formik.setFieldValue('year', e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="bimester"
            label="Bimestre"
            value={formik.values.bimester}
            helperText={formik.errors.bimester}
            error={formik.errors.bimester}
            required={isAClassWithBimester}
            disabled={!isAClassWithBimester}
            optionsList={bimesterOptions}
            onChange={(e) => formik.setFieldValue('bimester', e.target.value)}
          />
        </Grid>
      </Grid>
      <Styled.ButtonsContainer item xs={12}>
        <ButtonFooterV2
          labelClose="Cancelar"
          labelConfirm="Confirmar"
          size="medium"
          onClickClose={handleClose}
          onClickConfirm={(e) => formik.handleSubmit(e)}
          disabledConfirm={!formik.isValid || !formik.values.year}
        />
      </Styled.ButtonsContainer>
    </Styled.Form>
  )
}
