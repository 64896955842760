export const USER_EVENTS = {
  header: {
    btn: {
      openSidebar: 'user_header_btn_open_sidebar',
      openHome: 'user_header_btn_open_home'
    }
  },
  menu: {
    btn: {
      openPerfil: 'user_menu_btn_open_perfil',
      sair: 'user_menu_btn_sair'
    }
  },
  sidebar: {
    student: {
      btn: {
        openProfile: 'user_sidebar_btn_open_perfil',
        openDisciplines: 'student_sidebar_btn_open_disciplinas',
        openNotasEFaltas: 'student_sidebar_btn_open_notasefaltas',
        openFinanceiro: 'student_sidebar_btn_open_financeiro',
        openEAD: 'student_sidebar_btn_open_ead'
      }
    }
  }
}
