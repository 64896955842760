export const STUDENT_HUB_EVENTS = {
  btn: {
    openAtendimento: 'student_hub_btn_open_atendimento',
    openEAD: 'student_hub_btn_open_ead',
    openNotasEFaltas: 'student_hub_btn_open_notasefaltas',
    openFinanceiro: 'student_hub_btn_open_financeiro',
    openUnidade: 'student_hub_btn_open_unidade',
    openInstagram: 'student_hub_btn_open_instagram'
  },
  banner: {
    btn: {
      openAtendimento: 'student_hub_rematricula_banner_btn_open_atendimento',
      openGetnet: 'student_hub_mensalidade_banner_btn_open_getnet'
    }
  }
}
