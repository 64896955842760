import React, { lazy } from 'react'
import { Table, TableBody, TableContainer, Paper } from '@mui/material'
import { IsEmpty } from 'services/helpers'
import { Button } from 'components/atoms'
import * as Styled from './style'

const TableHeader = lazy(() => import('./TableHeader'))
const TableFooter = lazy(() => import('./TableFooter'))

const DetailsFinancial = ({ listDetailFinancial, onShowMore }) => (
  <>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHeader />
        <TableBody>
          {listDetailFinancial &&
            listDetailFinancial.map((row) => (
              <TableFooter key={row.id_titulo} row={row} />
            ))}
        </TableBody>
      </Table>
      {IsEmpty(listDetailFinancial) ? (
        <>
          <Styled.ShowMore>
            <Styled.Typography>Não há pendências Financeira</Styled.Typography>
          </Styled.ShowMore>
        </>
      ) : (
        <Styled.ShowMore>
          <Button
            variant="text"
            onClick={onShowMore}
            sx={{ padding: '16px 0' }}
          >
            <b>Ver mais</b>
          </Button>
        </Styled.ShowMore>
      )}
    </TableContainer>
  </>
)

export default DetailsFinancial
