import theme from 'theme/designTokens'
import generateDocDefinitionsBase from '../../shared/BasePage'
import { DOCUMENT_TITLE_ACADEMIC_RECORD_MG } from './constants'
import { coatOfArmsMgBase64 } from '../../shared/images/coatOfArmsMG'
import { buildCompanyInfo } from './CompanyInfo'
import { buildAcademicReportInfo } from './AcademicReportInfo'
import { buildPrimaryHeaderContent } from './PrimaryHeader'
import { buildSecondaryHeader } from './SecondaryHeader'
import { buildAcademicReportGrades } from './AcademicReportGrades'

export function buildMainContent({
  classInfo,
  student,
  gradeDetails,
  courseInfo,
  subjects,
  withSignature
}) {
  return [
    [
      buildAcademicReportInfo({
        student,
        courseInfo,
        classInfo,
        withSignature
      }),
      ...buildSecondaryHeader({ student, courseInfo }),
      buildAcademicReportGrades({
        gradeDetails,
        courseInfo,
        subjects,
        classInfo,
        withSignature
      })
    ]
  ]
}

export async function AcademicRecordPDFtypeMG({
  academicRecordData,
  withSignature
}) {
  const {
    classInfo,
    companyInfo: companyInfoList,
    students,
    studentAcademicReportInfo,
    courseInfo,
    subjects
  } = academicRecordData
  const signatureImage1 = classInfo?.signatures[0]?.image
  const signatureImage2 = classInfo?.signatures[1]?.image
  const [{ student, gradeDetails }] = students

  const studentInfo = {
    ...student,
    ...studentAcademicReportInfo
  }
  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      defaultStyle: {
        fontSize: 7,
        font: 'RobotoLight',
        lineHeight: 1.1
      },
      pageOrientation: 'portrait',
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },
      ...(signatureImage1 || signatureImage2
        ? {
            images: {
              ...(signatureImage1
                ? {
                    signature1: {
                      url: signatureImage1,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {}),
              ...(signatureImage2
                ? {
                    signature2: {
                      url: signatureImage2,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {})
            }
          }
        : {}),
      pageBreakBefore: (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) => {
        const shouldBreakBefore =
          currentNode?.id === 'signatureInfo' &&
          currentNode.pageNumbers.length > 1

        return shouldBreakBefore
      },
      pageMargins: [10, 10, 10, 30]
    }
  })

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE_ACADEMIC_RECORD_MG,
    mainContent: buildMainContent({
      classInfo,
      student: studentInfo,
      gradeDetails,
      courseInfo,
      subjects,
      withSignature
    }),
    companyInfo: buildCompanyInfo({ companyInfoList }),
    headerContent: buildPrimaryHeaderContent({
      state: 'Minas Gerais',
      rightImage: coatOfArmsMgBase64
    })
  })
}
