export function toCamelCase(text) {
  if (!text || typeof text !== 'string') return ''

  const essentialsPrepositions =
    'a, ante, até, após, com, contra, de, desde, em, entre, para, perante, por, sem, sob, sobre, trás'
  const toLowerCaseList = [...essentialsPrepositions.split(', '), 'e']

  return text
    .toLowerCase()
    .split(' ')
    .map((s) =>
      toLowerCaseList.includes(s)
        ? s.toLowerCase()
        : s.charAt(0).toUpperCase() + s.slice(1)
    )
    .join(' ')
}
