import htmlToPdfmake from 'html-to-pdfmake'
import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { clone, convertDateToUTC } from 'services/helpers'
import buildSignatureBase from '../../../shared/BasePage/Signature'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export function buildCourseObservations({
  courseInfo,
  classInfo,
  academicPerformanceList
}) {
  const signature = buildSignatureBase(
    classInfo.signatures,
    classInfo.unityCity
  )
  clone(signature)

  return {
    table: {
      body: [
        [
          {
            text: 'Observações do curso',
            style: ['tableHeaderCell'],
            colSpan: 3
          },
          {},
          {}
        ],
        [
          {
            text: [
              { text: 'Eixo Tecnológico  ', bold: true },
              courseInfo.technologicalAxis
            ]
          },
          {
            text: [
              { text: 'Data de Matrícula  ', bold: true },
              convertDateToUTC(classInfo.classStartDate).toLocaleDateString(
                'pt-BR'
              )
            ]
          },
          {
            text: [
              { text: 'Carga horária total  ', bold: true },
              courseInfo.theoreticalWorkload +
                (courseInfo.internshipWorkload || courseInfo.practicalWorkload)
            ]
          }
        ],
        [
          {
            stack: [
              {
                text: [
                  { text: 'Critério de avaliação  ', bold: true },
                  'É considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, nota final igual ou superior a 6 (seis) pontos e frequência igual ou superior a 75% (setenta e cinco por cento). Até janeiro de 2023, é considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, a nota final igual ou superior a 5 (cinco) pontos em cada unidade curricular e frequência igual ou superior a 75% (setenta e cinco por cento).'
                ]
              },
              ...(academicPerformanceList.length ||
              courseInfo.academicReportObservations
                ? [
                    {
                      text: [
                        { text: 'Observações  ', bold: true },
                        courseInfo.academicReportObservations
                      ]
                    }
                  ]
                : []),
              ...(academicPerformanceList.length
                ? academicPerformanceList.map((academicPerformance) => ({
                    text: `(*) Aluno com aproveitamento de estudos proveniente da Instituição de Ensino ${academicPerformance.schoolInstitution} no ano de ${academicPerformance.yearCompletion}.`
                  }))
                : [])
            ],
            colSpan: 3
          },
          {},
          {}
        ],
        [
          {
            stack: [htmlToPdfmake(courseInfo.gridComplementInformation)],
            colSpan: 3
          },
          {},
          {}
        ]
      ],
      widths: ['*', '*', '*']
    },
    layout: {
      ...borderLayoutDefault,
      paddingBottom: () => 3,
      paddingLeft: () => 3,
      paddingRight: () => 3,
      paddingTop: () => 3
    },
    pageBreak: 'before'
  }
}
