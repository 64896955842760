import buildObservations from 'components/templates/PDF/shared/Observation'
import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { UNITS } from 'services/constants'

const { borderLayoutDefault, noBorderOnTop, labelNumber } = utilsPDF

function BuildModulesMS({ subjects }) {
  const year = new Date(subjects[0].beginAt).getFullYear()
  return [
    {
      table: {
        body: [
          [
            {
              text: 'Unidades Curriculares',
              border: noBorderOnTop,
              style: ['tableHeaderCell']
            },
            {
              text: `Temáticas do 1º semestre/ ${year}`,
              border: noBorderOnTop,
              style: ['tableHeaderCell']
            },
            {
              text: `Temáticas do 2º semestre/ ${year}`,
              border: noBorderOnTop,
              style: ['tableHeaderCell']
            }
          ],
          ...subjects.map((subject) => {
            const sortedSubject = subject.subjects.sort((a, b) =>
              new Date(a.beginAt).getTime() > new Date(b.beginAt).getTime()
                ? 1
                : -1
            )

            const [subjectDeep1, subjectDeep2] = sortedSubject

            return [
              {
                text: subject.name
              },
              {
                text: subjectDeep1.name
              },
              {
                text: subjectDeep2.name
              }
            ]
          })
        ],
        widths: [240, '*', '*']
      },
      layout: {
        ...borderLayoutDefault,
        paddingBottom: () => 10,
        paddingLeft: () => 10,
        paddingRight: () => 10,
        paddingTop: () => 10
      }
    }
  ]
}

export function buildTableFooter({ classInfo, subjects }) {
  const isMS = classInfo.unit === UNITS.MS.name
  const minimumGrade = labelNumber[classInfo.minimumGrade] ?? labelNumber[6]

  return [
    ...(isMS ? BuildModulesMS({ subjects }) : []),
    {
      table: {
        body: [
          ...buildObservations({
            stack: [
              {
                text: `É considerado APROVADO(A) o(a) aluno(a) que obtiver a média final, igual ou superior a ${minimumGrade.value} (${minimumGrade.label}) pontos em cada disciplina e frequência igual ou superior a 75% (setenta e cinco) do total da carga horária. As notas precedidas de asterisco (*) estão abaixo do valor mínimo exigido para aprovação.`
              }
            ]
          })
        ],
        widths: [240, 60, '*']
      },
      layout: {
        ...borderLayoutDefault,
        paddingBottom: () => 10,
        paddingLeft: () => 10,
        paddingRight: () => 10,
        paddingTop: () => 10
      }
    }
  ]
}
